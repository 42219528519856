import "./index.css"
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import ReportLogIcon from "../../../assests/icons/reportLog";
import TableReports from "../../../Components/Tables/TableReports";
import FilterTableWithoutSearch from "../../../Components/TableTopFilters/FilterContentWithoutSearch";
import { reportData } from "../../../__mock__/report";
import { useNavigate } from "react-router-dom";
import { AVAILS_REPORT_ROUTE, SUPPLY_TRANSPARENCY_REPORT_ROUTE, USER_DATA_KEY } from "../../../Constant";
import getReportDownloadList from "../../../api/getReportDownloadsList";
import { getLocalStorageData } from "../../../utils/localStorageHelper";

import { downloadReportFile } from "../../../api/downloadReportFile";
import Notification from "../../../Components/Notification";
import {getCurrentDateWithMonthYear, getCurrentMonthAndYearString} from "../../../utils/helper";
import useWebStorageHelper from "../../../utils/sessionStorageHelper";


const ReportDetails = () => {
    const [loading, setLoading] = useState(false);
    const [contentData, setContentData] = useState([]);
    const [contentLength, setContentLength] = useState(0)
    const [EmailId, setEmailId] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState("")
    const [alertType, setAlertType] = useState("")
    const [currentMonthYear, setCurrentMonthYear] = useState("")

    const Navigation = useNavigate();


    useEffect(() => {
        const getCurrentMonthYear = getCurrentMonthAndYearString();
        setCurrentMonthYear(getCurrentMonthYear);
        getReportDownloadListData();
        useWebStorageHelper.removeOnbFile();
    }, [])

    const getReportDownloadListData = async () => {

        const user_data = getLocalStorageData(USER_DATA_KEY, true);
        let userEmail = user_data?.email || ""
        setEmailId(userEmail)
        setLoading(true)
        try {
            const dowloadsList = await getReportDownloadList(userEmail);
            setContentData(dowloadsList)
            setContentLength(dowloadsList.length)
            setLoading(false)
        } catch (error) {
            // Handle errors
            console.error("Error fetching Download List:", error);
            setLoading(false);
            return null;

        }
    };

    const handleNotificationClose = () => {
        setIsOpen(false);
    }
    const handleNotificationOpen = () => {
        setIsOpen(true);
    }
    const handleSetNotificationMessage = (message) => {
        setNotificationMessage(message)
    }

    const handleSetAlertType = (type) => {
        setAlertType(type)
    }



    return (
        <Grid container>
            <Grid item xs={12} className="amg-body-page-header">
                <Typography> Reports</Typography>
            </Grid>
            <Grid item xs={12} className="create-report-grid">
                <Typography className="create-report-typography"> Create a new report</Typography>
            </Grid>
            <Grid item xs={12} className="report-type-grid">
                <Grid container>
                     <Grid item xs={2} className="avails-grid"
                         onClick={() => Navigation(AVAILS_REPORT_ROUTE)}
                    >
                        <ReportLogIcon />
                        <Typography className="report-log-typography"> Avails and supply list ({currentMonthYear})</Typography>
                        <Typography className="report-log-sub-typography">This report provides information on all the inventory ownerships.</Typography>

                    </Grid>
                    <Grid item xs={2} className="avails-grid"
                          onClick={() => Navigation(SUPPLY_TRANSPARENCY_REPORT_ROUTE)}
                    >
                        <ReportLogIcon />
                        <Typography className="report-log-typography">Supply transparency report</Typography>
                        <Typography className="report-log-sub-typography"> This report provides details of all ad request parameters</Typography>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="download-report-header-grid">
                <Typography className="create-report-typography"> Download reports </Typography>
            </Grid>
            <FilterTableWithoutSearch
                titleText="Reports"
                count={contentLength}
                NoSearchBar={true}
            />
            <TableReports
                tableContent={contentData}
                count={contentLength}
                isLoading={loading}
                NotificationOpen={handleNotificationOpen}
                NotificationClose={handleNotificationClose}
                SetMessage={handleSetNotificationMessage}
                SetAlert={handleSetAlertType}
            />
            <Notification
                open={isOpen}
                message={notificationMessage}
                handleClose={handleNotificationClose}
                type={alertType}
            />
        </Grid>
    )

}

export default ReportDetails;
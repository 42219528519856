import {
    ONGOING_API_CALL_KEY,
    SELECTED_CHANNEL_KEY,
    SELECTED_PLATFORM_KEY,
    SELECTED_ROUTER_KEY,
    USER_DATA_KEY
} from "../Constant";

export const getLocalStorageData = (key = "", parse = false) => {
    const getLocalData = localStorage.getItem(key)

    // if(key===USER_DATA_KEY){
    //     let data = JSON.parse(getLocalData)
    //     data.user_roles.push("admin", "create_config")
    //     return data;
    // }
    if(parse){
        return JSON.parse(getLocalData)
    }

    return  getLocalData
}

export const setLocalStorageData = (key = "", parse = false, data = {}) => {
    let setLocalData = parse ? JSON.stringify(data) : data
    localStorage.setItem(key, setLocalData)

    return true
}

export const removeLocalStorageData = (key="") => {
    localStorage.removeItem(key)

    return true
}

export const logOutUser = () => {
    removeLocalStorageData(ONGOING_API_CALL_KEY)
    removeLocalStorageData(SELECTED_CHANNEL_KEY)
    removeLocalStorageData(SELECTED_PLATFORM_KEY)
    removeLocalStorageData(SELECTED_ROUTER_KEY)
    removeLocalStorageData(USER_DATA_KEY)
    window.location.href = "/";

}
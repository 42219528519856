export const AMG_LOGO = process.env.REACT_APP_AMG_LOGO || "https://storage.googleapis.com/ads-assets-ui/amagi_branding.png"
export const AMG_BRANDING_TEXT = "Amagi’s FAST Platform to easily manage content, distribution and monetization."
export const USER_DATA_KEY = "user_data"
export const NAV_ITEMS = [
    { "label": "Dashboard", "link": "/dashboard/ads-plus-dashboard/supply-health-tracker", "key": "ads-plus-dashboard" },
    { "label": "Platforms", "link": "/dashboard/platforms", "key": "platform" },
    { "label": "Activity Log", "link": "/dashboard/activity-log", "key": "activity-log" },
    { "label": "Reports", "link": "/dashboard/reports", "key": "report" },
    { "label": "Requests", "link":"/dashboard/requests", "key":"request" }
]
export const INITIAL_PAGE = "/dashboard/ads-plus-dashboard/supply-health-tracker"
export const MAX_ROW_COUNT_FROM_DB = 8
export const CREATE_CHANNEL_DETAILS_PATH = "/dashboard/channel/macros/"
export const CREATE_SUPPLY_ROUTER_PATH = "/dashboard/thunderstorm-tags/demand-tags/"
export const CREATE_DEMAND_TAGS_PATH = "/dashboard/demand-tag/macros/"
export const SUCCESS_NOTIFICATION_TYPE = "success"
export const WARNING_NOTIFICATION_TYPE = "warning"
export const ERROR_NOTIFICATION_TYPE = "error"
export const REQUEST_APPROVED_NOTIFICATION_TYPE ="New delivery request approved"
export const COPY_TO_CLIPBOARD_SUCCESS_NOTIFICATION_TYPE ="Copied to clipboard"
export const COPY_TO_CLIPBOARD_FAILED_NOTIFICATION_TYPE ="Failed to copy to clipboard"
export const TABLE_HEADER_PLATFORMS = ["PLATFORM NAME", "AMG ID", "REGION", "CHANNELS", "ROUTERS", "DEAL TYPE"]
export const PLATFORM_DETAILS_PAGE_LINK = "/dashboard/platform/channels/"
export const REQUEST_DETAILS_PAGE_LINK = "/dashboard/requests/view-details/"
export const EDIT_REQUEST_DETAILS_DRAFT_PAGE_LINK = "/dashboard/requests/edit-drafts/"
export const REQUESTS_LISTING_PAGE_LINK = "/dashboard/requests/"
export const TABS_PLATFORM = [{
    "name": "Channels",
    "value": "channels",
    "path": "/dashboard/platform/channels/"
}, { "name": "Demand Tags", "value": "demand_tags", "path": "/dashboard/platform/demand-tags/" }, {
    "name": "Macros",
    "value": "macros",
    "path": "/dashboard/platform/macros/"
}]
export const TABLE_HEADER_CHANNELS = ["selectAll", "CHANNEL NAME", "CHANNEL ID", "THUNDERSTORM TAGS", "SUPPLY PARTNER", "SUPPLY PARTNER ID", "LAST SYNCED"]
export const TABLE_HEADER_DEMAND_TAGS = ["selectAll", "DEMAND TAG NAME", "THUNDERSTORM TAGS", "DEMAND OWNER", "TIER", "AD SERVERS", "LAST SYNCED"]
export const TABLE_KEY_CHANNELS = ["selectAll", "name", "channel_id", "demand_tags", "supply_partner", "supply_partner_id", "last_updated"]
export const TABLE_KEY_DEMAND_TAGS = ["selectAll", "name", "thunderstorm_tag", "demand_partner", "tier", "demand_server", "last_synced"]
export const VIEW_CHANNEL_TYPE = "channels"
export const VIEW_THUNDERSTORM_TAGS = "thunderstorm_tags"
export const VIEW_DEMAND_TAG_TYPE = "demand_tags"
export const VIEW_SUPPLY_TAG_TYPE = "supply_tags"
export const VIEW_MACRO_TYPE = "macros"
export const LIMIT_STRING_SHOW = 70
export const TABS_CHANNEL = [{
    "name": "Macros",
    "value": "macros",
    "path": "/dashboard/channel/macros/"
}, {
    "name": "Supply Tags",
    "value": "supply_tags",
    "path": "/dashboard/channel/supply-tags/"
}, {
    "name": "Demand Tags",
    "value": "demand_tags",
    "path": "/dashboard/channel/demand-tags/"
}]


export const TABS_THUNDERSTORM = [
    { "name": "Demand Tags", "value": "demand_tags", "path": "/dashboard/thunderstorm-tags/demand-tags/" }]

export const BFF_LAYER_URI = process.env.REACT_APP_ADS_BFF_LAYER || "https://obs-spot-bff.stg.ads.amagi.tv/graphql"

export const MAX_LIMIT_SHOW_TABLE_DATA = 20
export const INTERNAL_AMG_ID = "AMG001"
export const POST_URI = process.env.REACT_APP_SELFSERVE_POST_URI || "https://self-serve.stg.ads.amagi.tv/api/v1/platform/";
export const VALIDATE_URI = process.env.REACT_APP_VALIDATE_URI || "https://self-serve.stg.ads.amagi.tv/api/v1/"
export const ONGOING_API_CALL_KEY = "api_call"
export const ALL_DATA_FETCHED = "All data has been fetched."
export const NO_DATA_AVAILABLE = "No data is available"
export const SELECTED_CHANNEL_KEY = "selected_channel"
export const SELECTED_PLATFORM_KEY = "selected_platform"
export const SELECTED_ROUTER_KEY = "selceted_router"
export const TITLE_HEADER_TYPE = {
    "channels": "Channels",
    "thunderstorm_tags": "Thunderstorm Tags",
    "demand_tags": "Demand Tags",
    "macros": "Macros"
}
export const SEVERITY_TYPE = "error"
export const SUCCESS_TYPE = "success"
export const JOB_SUCCESS = "Accepted"
export const SKELETON_COUNT = 5
export const DEMAND_TAG_TITLE_HEADER = "Add demand tags macros"
export const SUPPLY_ROUTER_TAG_TITLE_HEADER = "Add thunderstorm tags macros"
export const NO_DATA_FOUND_MESSAGE = "No data found."
export const DATA_IN_LOADING_STATE = "Some error happened. Try again"
export const NO_MACROS_FOUND = "No Macros found"
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "hMNuwKE33kFGqMcuzYMsRXB65qcpsK6C"
export const AUTH_GET_ACCESS_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN || "login.iota.amagi.tv"
export const VIEW_DEMAND_TAG_WITH_TS_API_CALL = "demand_tags_ts_api_call"
export const TS_TAG_UPDATE_SUCCESSFUL = "Job is accepted, and macro bulk is in progress"
export const DEMAND_TAG_UPDATE_SUCCESSFUL = "Job is accepted, and macro bulk is in progress"
export const TS_TAG_UPDATE_FAILED = "Macro update failed on TS Tags (may be for one or multiple).Check Activity Logo for more details."
export const DEMAND_TAG_UPDATE_FAILED = "Macro update failed on Demand Tags (may be for one or multiple).Check Activity Logo for more details."
export const USER_AUTHENTICATED = "User authenticated."
export const USER_NOT_AUTHENTICATED = "Please login to see the dashboard."
export const USER_VALIDATION_ON_GOING = "user_validation"
export const CURRENT_DEMAND_TAG_LIMIT = 500
export const TAG_IS_NOT_UPDATED = "Could Not Update the tags"
export const SEARCHED_TEXT_NOT_FOUND = "Matched data is not found."
export const WARNING_TYPE = "warning"
export const MACRO_NOT_SELECTED = "Macro list is empty. Please add macros."
export const AUTH_TOKEN = process.env.REACT_APP_SELFSERVE_AUTH_TOKEN || 'Bearer 9QFAkKHUWX'
export const AUDIT_AUTH_TOKEN = process.env.REACT_APP_AUDIT_AUTH_TOKEN || 'Bearer 9QFAkKHUWX'
export const LAST_JOB_ID = "job_id"
export const ERROR_FOUND = 404
export const TIMEOUT_NAVIGATION_CALL = 1000
export const SUCCESS_LOG_ACTIVITY = "Success"
export const FAILURE_LOG_ACTIVITY = "Failure"
export const REJECTED_LOG_ACTIVITY = "Rejected"
export const ACCEPTED_LOG_ACTIVITY = "Accepted"
export const MAX_LIMIT_SHOW_ACTIVITY_LOG = 1
export const AUDIT_TRAIL_URL = process.env.REACT_APP_AUDIT_TRAIL_URI || "https://audit-trail.stg.ads.amagi.tv/api/v1"
export const SUCCESS_TYPE_LOG_FILTER = "SuccessType"
export const FAILURE_TYPE_LOG_FILTER = "FailedType"
export const ACCEPTED_TYPE_LOG_FILTER = "AcceptedType"
export const AVAILS_REPORT_ROUTE = "/dashboard/reports/avails-supply-report"
export const SUPPLY_TRANSPARENCY_REPORT_ROUTE = "/dashboard/reports/supply-transparency-report"
export const SELECT1_OPTION_STR = [
    'Stream ID',
    'Supply tag ID',
    'Supply partner ID',
    'Supply partner name',
    'Date',
    'Total Ad requests',
    'Total impressions'
];

export const SELECT_COMPARISON_NUM = ["Greater than or equal to (>=)", "Less than or equal to (<=)"]
export const SELECT_COMPARISON_STRING = ["is", "is not"]
export const SELECT_COMPARISON_EQUALS = ["Equals to (=)"]
export const SUPPLY_TRANSPARENCY_COLUMNS = [
    { id: "supply_tag_id", title: "Supply tag ID" },
    { id: "supply_partner_id", title: "Supply partner ID" },
    { id: "supply_tag_name", title: "Supply tag name" },
    { id: "stream_id", title: "Stream ID" },
    { id: "total_ad_request", title: "Total ad requests" },
    { id: "total_impression", title: "Total impressions" },
    { id: "app_bundle", title: "App bundle" },
    { id: "coppa", title: "COPAA" },
    { id: "device_type_id", title: "Device type ID" },
    { id: "dnt", title: "DNT" },
    { id: "gdpr", title: "GDPR" },
    { id: "gdpr_consent_present", title: "GDPR consent present" },
    { id: "lmt", title: "LMT" },
    { id: "full_page_url", title: "Full page URL" },
    { id: "us_privacy", title: "US privacy" },
    { id: "channel_name", title: "Channel name" },
    { id: "content_genre_list", title: "Content genre list" },
    { id: "language", title: "Language" },
    { id: "declared_player_size", title: "Declared player size" },
    { id: "content_rating_list", title: "Content rating list" },
    { id: "livestream", title: "Live stream" },
    { id: "country", title: "Country" },
    { id: "device_id", title: "Device ID" },
    { id: "user_ip", title: "User IP" },
    { id: "user_agent", title: "User agent" }
];

export const REPORT_BFF_URI = process.env.REACT_APP_ADS_PLUS_BFF || "https://ap-reporting.stg.ads.amagi.tv/graphql"
export const REPORT_DOWNLOAD_URI = process.env.REACT_APP_ADS_DOWNLOADER || "https://ap-downloader.stg.ads.amagi.tv"

export const TODAYS_DATE = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000) - 2 * 86400000).toISOString().split('T')[0];
export const MAX_DATE = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000) - 86400000).toISOString().split('T')[0];
export const MAX_DATE_FILTER = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().split('T')[0];
export const ACTIVE_MENU_PATH = [
    { "path": "/dashboard/platforms", "key": "platform", "active": true },
    { "path": "/dashboard/platform/channels", "key": "platform", "active": true },
    { "path": "/dashboard/platform/demand-tags", "key": "platform", "active": true },
    { "path": "/dashboard/platform/macros", "key": "platform", "active": true },
    { "path": "/dashboard/channel/macros", "key": "platform", "active": true },
    { "path": "/dashboard/channel/thunderstorm-tags", "key": "platform", "active": true },
    { "path": "/dashboard/channel/supply-tags", "key": "platform", "active": true },
    { "path": "/dashboard/channel/demand-tags", "key": "platform", "active": true },
    { "path": "/dashboard/thunderstorm-tags/demand-tags", "key": "platform", "active": true },
    { "path": "/dashboard/ads-plus-dashboard/supply-health-tracker", "key": "ads-plus-dashboard", "active": true },
    { "path": "/dashboard/ads-plus-dashboard/demand-health-tracker", "key": "ads-plus-dashboard", "active": true },
    { "path": "/dashboard/activity-log", "key": "activity-log", "active": true },
    { "path": "/dashboard/reports", "key": "report", "active": true },
    { "path": "/dashboard/reports/supply-transparency-report", "key": "report", "active": true },
    { "path": "/dashboard/reports/avails-supply-report", "key": "report", "active": true },
    { "path": "/dashboard/requests", "key": "request", "active": true },
    { "path": "/dashboard/requests/view-details", "key": "request", "active": true }
]
export const SUPPLY_HEALTH_TRACKER_PATH = "/dashboard/ads-plus-dashboard/supply-health-tracker"
export const DEMAND_HEALTH_TRACKER_PATH = "/dashboard/ads-plus-dashboard/demand-health-tracker"
export const SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE = "Supply Health Tracker"
export const DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE = "Demand Health Tracker"
export const ADS_PLUS_INVENTORY_TYPE_FILTER = [
    { "value": "o_o", "label": "Amagi Ads Plus O&O" },
    { "value": "non_o_o", "label": "Amagi Ads Plus Non O&O" }
]
export const ADS_PLUS_DASHBOARD_FILTER_CONFIG_SUPPLY = {
    "inventory_type": "",
    "date": { "gte": "", "lte": "" },
    "supply_partner": { "in": [] }
}
export const ADS_PLUS_DASHBOARD_FILTER_CONFIG_DEMAND = {
    "demand_partner_id": { "in": [] },
    "date": { "gte": "", "lte": "" }
}
export const QUICK_ADS_PLUS_SUPPLY_FILTER_MAPPING = {
    "revenue": {
        "denote": "currency",
        "label": "Revenue"
    },
    "impressions": {
        "denote": "number",
        "label": "Impression"
    },
    "ecpm": {
        "denote": "currency",
        "label": "eCPM"
    },
    "ad_requests": {
        "denote": "number",
        "label": "Ad Request"
    },
    "margin_per": {
        "denote": "percentage",
        "label": "Margin Rate"
    },
    "fill_rate": {
        "denote": "percentage",
        "label": "Fill Rate"
    }
}
export const QUICK_ADS_PLUS_DEMAND_FILTER_MAPPING = {
    "revenue": {
        "denote": "currency",
        "label": "Revenue"
    },
    "impressions": {
        "denote": "number",
        "label": "Impression"
    },
    "erpm": {
        "denote": "currency",
        "label": "eRPM"
    },
    "bid_rate": {
        "denote": "percentage",
        "label": "Bid Rate"
    },
    "win_rate": {
        "denote": "percentage",
        "label": "Win Rate"
    },
    "use_rate": {
        "denote": "percentage",
        "label": "Use Rate"
    }
}
export const NUMBER_DENOTE_TYPE = "number"
export const CURRENCY_DENOTE_TYPE = "currency"
export const PERCENTAGE_DENOTE_TYPE = "percentage"
export const SUPPLY_DASHBOARD_BAR_CHART_VIEW_TYPE = ["Ad requests & Impressions", "Margins & Fill Rate"]
export const DEMAND_DASHBOARD_BAR_CHART_VIEW_TYPE = ["Demand Summary", "Open Market Summary"]
export const BAR_CHART_VIEW_TYPE = "bar-chart"
export const TABLE_VIEW_TYPE = "table"
export const AD_REQUEST_AND_IMPRESSION = "Ad requests & Impressions"
export const MARGIN_AND_FILL_RATE = "Margins & Fill Rate"
export const OPEN_MARKET_SUMMERY = "Open Market Summary"
export const DEMAND_MARKET_SUMMERY = "Demand Summary"
export const ADS_TYPE_CONVERSION = {
    "Revenue": "currency",
    "Impressions": "number",
    "E CPM": "currency",
    "Ad Requests": "number",
    "Margin": "percentage",
    "Fill Rate": "percentage",
    "eRPM": "number",
    "Bid Rate": "percentage",
    "Win Rate": "percentage",
    "Use Rate": "percentage",
}
export const DEFAULT_DOWNLOAD_API_RESPONSE_TIME = 3000
export const TOTAL_REVENUE_KEY = "revenue"
export const DECREASE_REVENUE_NUMBER = 2000
export const INCREASE_REVENUE_NUMBER = 4000
export const SUPPLY_PARTNER_TABLE_DETAILS = ["REVENUE", "MEDIA COST", "NET REVENUE", "REQUESTS", "IMPRESSIONS", "PT REQUESTED", "PT Filled", "eCPM", "RPM", "TIMEOUTS","MARGIN PERCENTAGE", "PT FILL RATE", "FILL RATE"]
export const DEMAND_PARTNER_TABLE_DETAILS = ["REVENUE", "MEDIA COST", "MARGIN", "MARGIN PERCENTAGE","REQUESTS", "BIDS", "WINS", "IMPRESSION", "BID RATE", "WIN RATE", "USE RATE", "eRPM","TIMEOUTS"]
export const DEMAND_PARTNER_OPEN_MARKET_TABLE_DETAILS = ["REVENUE", "MEDIA COST", "MARGIN", "MARGIN PERCENTAGE","REQUESTS", "BIDS", "WINS", "IMPRESSION", "BID RATE", "WIN RATE", "USE RATE", "eRPM","BIDDER ERRORS"]
export const CSV_FORMAT = ".csv"
export const DEFAULT_SELECTED_COLUMN_STR =
    [
        "date",
        "supply_tag_id",
        "supply_partner_id",
        "supply_tag_name",
        "stream_id",
        "total_ad_request",
        "total_impression",
        "app_bundle",
        "coppa",
        "device_type_id",
        "dnt",
        "gdpr",
        "gdpr_consent_present",
        "lmt",
        "full_page_url",
        "us_privacy",
        "channel_name",
        "content_genre_list",
        "language",
        "declared_player_size",
        "content_rating_list",
        "livestream",
        "country",
        "device_id",
        "user_ip",
        "user_agent",
        "app_name"
    ]
export const REPORT_LANDING_PAGE = "dashboard/reports"
export const ITEMS_PER_PAGE = 10;
export const TOP_20_SUPPLY_PARTNER = [
    65209,
    64987,
    60834,
    61337,
    68144,
    60164,
    58909,
    60916,
    62171,
    60784,
    59183,
    69346,
    68823,
    63159,
    60029,
    65039,
    60017,
    62574,
    68314,
    68908
]
export const DEFAULT_INVENTORY_TYPE = ["o_o", "non_o_o"]
export const NO_SUPPLY_PARTNER_DATA = "Not able to fetch supply partner data"
export const NO_DEMAND_PARTNER_DATA = "Not able to fetch demand partner data"
export const NO_SUPPLY_HEALTH_TRACKER_DATA = "Not able to fetch supply health tracker data"
export const NO_DEMAND_HEALTH_TRACKER_DATA = "Not able to fetch demand health tracker data"
export const GETTING_THE_DATA = "We are getting the data, hold a bit."
export const SAMPLE_CSV_DOWNLOAD_DATA = [
    { name: 'John Doe', age: 28, city: 'New York' },
    { name: 'Jane Smith', age: 32, city: 'Los Angeles' },
    // Add more data as needed
];
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || ""

export const TOP_20_DEMAND_PARTNER = [
    56346, 62862, 59284, 63824, 71923, 65273, 69895, 64857,
    59000, 56344, 62992, 67867, 66247, 62823, 65325, 56348,
    63456, 57216, 56338, 66292
]
export const SUPPLY_PARTNER_KEY = ["revenue", "cost", "net_revenue", "ad_requests", "impressions", "pod_time_requested", "pod_time_filled", "ecpm", "rpm", "timeouts", "margin_per", "pod_time_fill_rate", "fill_rate"]
export const DEMAND_PARTNER_KEY = ["revenue", "cost", "margin", "margin_per", "requests", "bids", "wins", "impressions", "bid_rate", "win_rate", "use_rate", "erpm", "timeouts"]
export const DEMAND_PARTNER_OPEN_MARKET_KEY = ["revenue", "cost", "margin", "margin_per", "requests", "bids", "wins", "impressions", "bid_rate", "win_rate", "use_rate", "erpm", "bidder_errors"]
export const ALL_PARTNER_KEY_DENOTES = {
    "revenue": "currency",
    "cost": "currency",
    "net_revenue": "currency",
    "ad_requests": "number",
    "impressions": "number",
    "pod_time_fill_rate": "percentage",
    "pod_time_filled": "number",
    "pod_time_requested": "number",
    "ecpm": "number",
    "rpm": "currency",
    "timeouts": "number",
    "margin_per": "percentage",
    "fill_rate": "percentage",
    "supply_timeouts": "number",
    "bids": "number",
    "wins": "number",
    "bid_rate": "percentage",
    "win_rate": "percentage",
    "use_rate": "percentage",
    "margin": "number",
    "erpm": "percentage",
    "requests": "number",
    "bidder_errors": "number"
}
export const AUTO_HIDE_DOWNLOAD_TIME = 5000

export const SORT_STR_LIST =[
    "Total ad requests (High to low)",
    "Total ad requests (Low to high)",
    "Total impressions (High to low)",
    "Total impressions (Low to High)"
]

export const TADR_HTL ="Total ad requests (High to low)"
export const TADR_LTH ="Total ad requests (Low to high)"
export const TI_HTL ="Total impressions (High to low)"
export const TI_LTH ="Total impressions (Low to High)"
export const DATE_PICKER_FORMAT  = "MMM D, YYYY"
export const YEAR_CHECK_DATA_PRESENT = 2024
export const MONTH_CHECK_DATA_PRESENT = 4
export const QUATAR_DATA_PRESENT = [
    {"value": "Q1", "label": "FY Q1 ‘24", "start_month": 4, "year": 2024},
    {"value": "Q2", "label": "FY Q2 ‘24", "start_month": 7, "year": 2024},
    {"value": "Q3", "label": "FY Q3 ‘24", "start_month": 10, "year": 2024},
    {"value": "Q4", "label": "FY Q4 ‘25", "start_month": 1, "year": 2025}]
export const Q1_MONTTHS_WISE = ["APR", "MAY", "JUN"]
export const Q2_MONTTHS_WISE = ["JUL", "AUG", "SEP"]
export const Q3_MONTTHS_WISE = ["OCT", "NOV", "DEC"]
export const Q4_MONTTHS_WISE = ["JAN", "FEB", "MAR"]
export const CUMULATIVE_ALL_MONTH_VALUE = "all"
export const Q_ONE_VALUE = "Q1"
export const Q_TWO_VALUE = "Q2"
export const Q_THREE_VALUE = "Q3"
export const Q_FOUR_VALUE = "Q4"
export const CUMULATIVE_CONFIG_DATA = {
    "month": "all",
    "target": 0,
    "projected": 0,
    "achieved": 0,
    "track": "",
    "achieved_precision": 0,
    "projected_precision": 0
}
export const INCREMENT_ON_NUMBER = 1000000
export const ON_TRACK_KEY = "on-track"
export const UNDER_PACING_KEY = "under-pacing"
export const OVER_PACING_KEY = "over-pacing"
export const GOAL_STATUS_LABEL = {
    "on-track": "On Track",
    "under-pacing": "Under Pacing",
    "over-pacing": "Over Pacing",
}
export const DEMAND_GOAL_WHERE_CLAUSE = {
    month: 0,
    year: 2024,
    goal_name: "revenue"
}

export const FILTER_VALUE_FOR_LOG =[
    {
        heading: 'Users',
        data: []
    },
    {
        heading: 'Status',
        data: []
    },
    {
        heading: 'gteDate',
        data:""
    },
    {
        heading: 'lteDate',
        data:""
    }
]

export const AVAILS_FILTER_CONFIG = {
    "AD QUALITY": [
        {
            "label": "Political",
            "operator": [
                {
                    "label": "is",
                    "value": "in"
                }, {
                    "label": "is not",
                    "value": "notIn"
                }
            ],
            "value": "political_policy",
            "type": "string"
        },
        {
            "label": "Cannabis",
            "operator": [
                {
                    "label": "is",
                    "value": "in"
                }, {
                    "label": "is not",
                    "value": "notIn"
                }
            ],
            "value": "cannabis_policy",
            "type": "string"
        },
        {
            "label": "Gambling",
            "operator": [
                {
                    "label": "is",
                    "value": "in"
                }, {
                    "label": "is not",
                    "value": "notIn"
                }
            ],
            "value": "gambling_policy",
            "type": "string"
        }
    ],
    "CPM": [
        {
            "label": "Floor price",
            "operator": [
                {
                    "label": "Greater than or equal to (>=)",
                    "value": "gte"
                }, {
                    "label": "Less than or equal to (<=)",
                    "value": "lte"
                }
            ],
            "value": "floor_price",
            "type": "integer"
        },
        // {
        //     "label": "Average clearing price",
        //     "operator": [
        //         {
        //             "label": "Greater than or equal to (>=)",
        //             "value": "gte"
        //         }, {
        //             "label": "Less than or equal to (<=)",
        //             "value": "lte"
        //         }
        //     ],
        //     "value": "average_clearing_price",
        //     "type": "integer"
        // }
    ],
    "GEO": [
        {
            "label": "Country",
            "operator": [
                {
                    "label": "is",
                    "value": "in"
                }, {
                    "label": "is not",
                    "value": "notIn"
                }
            ],
            "value": "country",
            "type": "string"
        },
        {
            "label": "Region",
            "operator": [
                {
                    "label": "is",
                    "value": "in"
                }, {
                    "label": "is not",
                    "value": "notIn"
                }
            ],
            "value": "region",
            "type": "string"
        },
        {
            "label": "States",
            "operator": [
                {
                    "label": "is",
                    "value": "in"
                }, {
                    "label": "is not",
                    "value": "notIn"
                }
            ],
            "value": "state",
            "type": "string"
        },
        {
            "label": "DMA",
            "operator": [
                {
                    "label": "is",
                    "value": "in"
                }, {
                    "label": "is not",
                    "value": "notIn"
                }
            ],
            "value": "dma_name",
            "type": "string"
        }
    ],
    "OTHERS": [
        {
            "label": "Amagi monetization rights",
            "operator": [
                {
                    "label": "is",
                    "value": "in"
                }, {
                    "label": "is not",
                    "value": "notIn"
                }
            ],
            "value": "amagi_monetization_rights",
            "type": "string"
        },
        {
            "label": "Device type",
            "operator": [
                {
                    "label": "is",
                    "value": "in"
                }, {
                    "label": "is not",
                    "value": "notIn"
                }
            ],
            "value": "device_type",
            "type": "string"
        },
        {
            "label": "Languages",
            "operator": [
                {
                    "label": "is",
                    "value": "in"
                }, {
                    "label": "is not",
                    "value": "notIn"
                }
            ],
            "value": "language",
            "type": "string"
        },
        {
            "label": "Genre",
            "operator": [
                {
                    "label": "has",
                    "value": "hasSome"
                }
            ],
            "value": "genre",
            "type": "string"
        },
        {
            "label": "COPPA",
            "operator": [
                {
                    "label": "is",
                    "value": "equal"
                }
            ],
            "value": "coppa",
            "type": "boolean"
        },
        {
            "label": "Live",
            "operator": [
                {
                    "label": "is",
                    "value": "equal"
                }
            ],
            "value": "live",
            "type": "boolean"
        }
    ]
}

export const LIMIT_CHIP_SHOW = 4
export const SAME_CRITERIA_TYPE_MESSAGE = "Same criteria already selected"
export const SAME_CRITERIA_TYPE_ON_CHANGE = "Selected criteria already has an operator"
export const AVAILS_CONFIG = {
    "political": {}
}
export const NO_CRITERIA_SELECTED = "Please select criteria."
export const NO_REPORT_NAME_ENTERED = "Please enter report name."
export const NO_FILTER_APPLIED = "Please add a criteria."
export const STR = "supply_transparency_report"
export const AVAILS = "avails_report"
export const AVAILS_TAB = "Avails"
export const SUPPLY_LIST = "supply_list"
export const AVAILS_TAB_VIEW = ["Avails", "Supply list"]
export const AVAILS_AVAILS_TAB_COLUMNS = [
    { id: 'amagi_monetization_rights', title: 'AMAGI MONETIZATION RIGHTS' },
    { id: 'supply_partner_name', title: 'SUPPLY PARTNER' },
    { id: 'channel_name', title: 'CHANNEL' },
    { id: 'device_type', title: 'DEVICE TYPE' },
    { id: 'country', title: 'COUNTRY' },
    {id:'region', title: 'REGION'},
    { id: 'monthly_available_30s', title: 'MONTHLY 30S' },
    { id: 'max_floor_price', title: 'FLOOR PRICE' },
    { id: 'average_clearing_price', title: 'AVERAGE CLEARING PRICE' }
];
export const AVAILS_SUPPLY_LIST_TAB_COLUMN = [
    { id: 'amagi_monetization_rights', title: 'AMAGI MONETIZATION RIGHTS' },
    { id: 'supply_partner_name', title: 'SUPPLY PARTNER' },
    {id:'channel_name', title: 'CHANNEL'},
    { id: 'app_site_name', title: 'APP/SITE' },
    { id: 'operating_system', title: 'OS' },
    { id: 'device_type', title: 'DEVICE TYPE' },
    { id: 'bundle_id', title: 'BUNDLE ID/DOMAIN' },
    { id: 'app_store_url', title: 'APP STORE URL' }
];
export const AVAILS_WHERE_QUERY = {
    // "where": {
    //     "average_clearing_price": {
    //         "gte": 0
    //     },
    //     "cannabis_policy": {
    //         "in": "",
    //         "notIn": ""
    //     },
    //     "coppa": {
    //         "equals": true
    //     },
    //     "country": {
    //         "notIn": "",
    //         "in": ""
    //     },
    //     "device_type": {
    //         "notIn": "",
    //         "in": ""
    //     },
    //     "dma_name": {
    //         "notIn": "",
    //         "in": ""
    //     },
    //     "floor_price": {
    //         "gte": 0
    //     },
    //     "gambling_policy": {
    //         "in": "",
    //         "notIn": ""
    //     },
    //     "genre": {
    //         "hasSome":""
    //     },
    //     "inventory_ownership": {
    //         "equals": ""
    //     },
    //     "language": {
    //         "in": "",
    //         "notIn": ""
    //     },
    //     "live": {
    //         "equals": true
    //     },
    //     "political_policy": {
    //         "in": "",
    //         "notIn": ""
    //     },
    //     "region": {
    //         "notIn": "",
    //         "in": ""
    //     },
    //     "state": {
    //         "in": "",
    //         "notIn": ""
    //     }
    // }
}
export const AVAILS_REPORT_DOWNLOAD = {
    "report_download": {
        "user_id": "",
        "name": "",
        "format": "xlsx"
    }
}
export const OPERATOR_MAP_STRING = {
    "is": "in",
    "is not": "notIn",
    "has": "hasSome"
}
export const OPERATOR_MAP_NUMBER = {
    "Greater than or equal to (>=)": "gte",
    "Less than or equal to (<=)": "lte",
}
export const OPERATOR_MAP_BOOLEAN = {
    "is": "equals"
}
export const AVAILS_API_ERROR = "Not able to get report data."
export const DATE_THRESHOLD_DASHBOARD = 1
export const NA = "Not available"

export const DEVICE_TYPE_OPTIONS = [
    {value: "web", label: "Mobile WEB"},
    {value: "ctv", label: "CTV"},
    {value: "in-app", label: "In App"},
    {value: "desktop", label: "Desktop"},
]
export const DEVICE_TYPE_OPTIONS_MAP = {
    "mobile_web": "Mobile WEB",
    "ctv": "CTV",
    "in_app": "In App",
    "desktop": "Desktop",
    "dooh": "DOOH"
}
export const MULTI_SELECT_TYPE = "multi-select"
export const SIMPLE_SELECT_TYPE = "select"
export const TEXT_INPUT_TYPE = "text_input"
export const TYPE_INPUT = "input"
export const TYPE_RADIO = "radio"
export const TYPE_VECTOR = "vector"
export const WIDTH_300_PX = "300px"
export const TS_PLATFORM_TYPE = "TS"
export const NON_TS_PLATFORM_TYPE = "NON_TS"
export const ADS_PLUS_PLATFORM_ID = "AMG001"
export const REQUEST_LISTING_PAGE_TYPE = "REQUEST_LISTING"
export const REQUEST_DETAILS_PAGE_TYPE = "REQUEST_DETAILS"
export const AD_OPS_USER_ROLE = "adsMonetization_adops_users"
export const ADMIN_ROLE = "adsMonetization_admin_users"
export const BUSINESS_USER_ROLE = "adsMonetization_business_users"
export const STATUS_APPROVED = "Approved"
export const STATUS_APPROVAL_PENDING = "Approval pending"
export const STATUS_DRAFT = "Draft"
export const REQUEST_STATUS_CREATE = "create"
export const REQUEST_STATUS_REVIEW = "review"
export const REQUEST_STATUS_DRAFT = "draft"
export const REQUEST_STATUS_SUBMIT = "submit"
export const ORG_ID = process.env.REACT_APP_AUTH_ORG_ID || "org_l1xmH1022UY95vf1"
export const DYNAMIC_MACROS = [
    {value: "app_name_in_app_store", label: "App name in app store"},
    {value: "channel_name_stream", label: "Channel name/Stream"},
    {value: "app_bundle_id", label: "App bundle ID"},
    {value: "app_store_url", label: "App store URL"},
]

export const DEFAULT_CUSTOMER_CONNECTIONS = [
    {
        type: "partner_primary_poc",
        label: "Partner Primary POC",
        full_name: "",
        email_id: ""
    },
    {
        type: "project_lead",
        label: "Project Lead",
        full_name: "",
        email_id: ""
    },
    {
        type: "ad_ops_contact",
        label: "Ad Ops Contact",
        full_name: "",
        email_id: ""
    }
];
export const REQUEST_CREATE_KEY = "create"
export const REQUEST_CONFIG_SESSION_KEY = "request_context_data"

// Map between XLSX labels and internal keys
export const DEVICE_DISTRIBUTION_XL = {
    "CTV": "ctv",
    "Desktop": "desktop",
    "DOOH": "dooh",
    "Mobile In-App": "in_app",
    "Mobile Web": "mobile_web"
};
 export const GEO_DISTRIBUTION_XL = {
     "NA": "NA",
     "EMEA":"EMEA",
     "APAC":"APAC",
     "LATAM":"LATAM"
}
export const SUPPLY_LIST_SAMPLE_FILE_URL = "https://storage.googleapis.com/ads-assets-ui/Supply%20Form%20.xlsx"
export const NON_TS_SAMPLE_FILE_URL = "https://storage.googleapis.com/ads-assets-ui/Non%20TS%20Config%20Template.xlsx"
export const TS_SAMPLE_FILE_URL = "https://storage.googleapis.com/ads-assets-ui/TS%20Config%20File%20Template.xlsx"
export const ADS_PLUS_DOWNLOAD_SERVICE = process.env.REACT_APP_ADS_PLUS_URL || "https://adsplus-self-serve.dev.ads.amagi.tv"
export const ADS_PLUS_SELF_SERVE_TOKEN = process.env.REACT_APP_ADS_PLUS_SELFSERVE_TOKEN || "Bearer 9QFAkKHUWXZZTTQQ"
export const FILE_UPLOAD_FAILURE_MSG = "File didn't upload, Please try again."
export const FILE_UPLOAD_SUCCESS_MSG = "File(s) uploaded successfully."
export const ENTER_REQUIRED_DETAILS = "Enter required details"
export const PLATFORM_LISTING_PAGE = "/dashboard/platforms"
export const COMMENTS_SUCCESS_NOTIFICATION_TYPE = "Comment added successfully"
export const COMMENTS_FAILURE_NOTIFICATION_TYPE = "Error adding comment"
export const INVENTORY_RELATIONS_SHIP_MAP = {
     "ono": "O&O",
    "exclusive": "Exclusive",
    "direct": "Direct",
    "reseller": "Reseller"
}
export const OPERATING_SYSTEM_MAP = {
     "iOS": "iOS",
    "Windows": "Windows",
    "Android": "Android"
}
export const DEVICE_DISTRIBUTION_MAP = {
    "ctv": "CTV",
    "desktop": "Desktop",
    "dooh": "DOOH",
    "in_app": "Mobile In-App",
    "mobile_web": "Mobile Web"
};
export const GENERAL_NECESSARY_FIELD = [
    { key: "channel_name", label: "Channel name" },
    { key: "customer_name", label: "Customer name" },
    { key: "ads_plus_supply_partner_id", label: "Supply partner" },
    { key: "connection_type", label: "Connection type" },
    { key: "deal_type", label: "Deal type" },
];
export const TS_GENERAL_NECESSARY_FIELD = [
    { key: "channel_name", label: "Channel name" },
    { key: "customer_name", label: "Customer name" },
    { key: "ads_plus_supply_partner_id", label: "Supply partner" },
    { key: "connection_type", label: "Connection type" },
    { key: "deal_type", label: "Deal type" },
    {key:"ss_core_owner_supply_tag_id", label:"Supply Tag"}
];

export const OTHER_NECESSARY_FIELD = [
    { key: "content_language", label: "Content language" },
    { key: "device_type", label: "Device distribution" },
    { key: "inventory_available_in_geo", label: "Geo Distribution" },
    { key: "ads_txt_content_url", label: "Ads.txt URL" },
    { key: "app_ads_txt_content_url", label: "App-ads.txt URL" },
    {label: "Max. creative length", key:"max_creative_length"}
];
export const TS_OTHER_NECESSARY_FIELD = [
    { key: "content_language", label: "Content language" },
    { key: "device_type", label: "Device distribution" },
    { key: "inventory_available_in_geo", label: "Geo Distribution" },
    { key: "ads_txt_content_url", label: "Ads.txt URL" },
    { key: "app_ads_txt_content_url", label: "App-ads.txt URL" }
];

export const MINIMUM_SUPPLY_TAG_ERROR ="At least 1 supply tag should be added (Under App Supply Details)"

export const ALL_KEYS_IN_APP_SUPPLY_SHOULD_HAVE_VALUES = "All fields in App Supply Details must have values."

export const DEVICE_PERCENTAGE_NOT_EQUAL_100 = "The total percentage in Device Distribution must equal 100%"
export const GEO_PERCENTAGE_NOT_EQUAL_100 = "The total percentage in Geo Distribution must equal 100%"
export const DEFAULT_TTL_TIME = 86400
export const JWT_TOKEN_KEY = "jwt_token"
export const AUTH_AUD_VALUE = process.env.REACT_APP_AUDIANCES || "https://amagi.adsMonetization.com"
export const BASIC_SENSITIVITY_CATEGORY_BLOCKLIST = [
    "Sexuality (IAB7-39)",
    "Card games (IAB9-7)",
    "Unmoderated UGC (IAB25-1)",
    "Extreme Graphic/Explicit violence (IAB25-2)",
    "Pornography (IAB25-3)",
    "Profane Content (IAB25-4)",
    "Hate Content (IAB25-5)",
    "Under Construction (IAB25-6)",
    "Incentivized (IAB25-7)",
    "Illegal Content(IAB26-1)",
    "Warez (IAB26-2)",
    "Spyware/Malware (IAB26-3)",
    "Copyright Infringement (IAB36-4)"
]

export const SUPPLY_APP_DATA_MAX_LIMIT = 25;

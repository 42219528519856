import React, {useContext, useEffect, useState} from "react";
import "./index.css";
import { Grid } from "@mui/material";
import VectorFullWidthIcon from "../../../assests/icons/vectorFullWidthIcon";
import GeneralRequestForm from "../../../Components/GeneralRequestForm";
import OtherDetails from "../../../Components/AdsComponent/OtherDetails";
import Notification from "../../../Components/Notification";
import OnbConfigFile from "../../../Components/AdsComponent/OnbConfigFile";
import RequestActions from "../RequestActions";
import {NonTSotherDetailsFormConfig} from "../../../FormConfig/NonTSotherDetailsConfig";
import useWebStorageHelper from "../../../utils/sessionStorageHelper";
import {
    NON_TS_PLATFORM_TYPE, PLATFORM_DETAILS_PAGE_LINK,
    REQUEST_CONFIG_SESSION_KEY, TS_PLATFORM_TYPE,
    USER_DATA_KEY,
    WARNING_NOTIFICATION_TYPE
} from "../../../Constant";
import {isEmpty} from "../../../utils/helper";
import {NonTSRequestGeneralDetailsFormConfig} from "../../../FormConfig/NonTSRequestGeneralDetails";
import getAdsPlusAllSupplyPartner from "../../../api/getAdsPlusAllSupplyPartner";
import {useNavigate, useParams} from "react-router-dom";
import {getConfigDetailsByJobID} from "../../../api/getConfigDetailsByJobID";
import {mapApiDataToContext} from "../../../FormConfig/RequestApiToContextMapping";
import {RequestContext} from "../../../Context/RequestContext";
import {TSRequestGeneralDetailsFormConfig} from "../../../FormConfig/TSRequestGeneralDetails";
import {TSOtherDetailsFormConfig} from "../../../FormConfig/TSOtherDetailsConfig";
import {getSupplyTagsByChannelId} from "../../../api/getSupplyTagsByChannelId";
import LazyLoading from "../../../Components/CommonScreens/LazyLoading";

const RequestDetailsInput = (props) => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [notificationType, setNotificationType] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [preLoadData, setPreLoadData] = useState({})
    const [generalFormModuleData, setGeneralFormModuleData] = useState([]);
    const [configFormModuleData, setConfigFormModuleData] = useState([]);
    const { requestId } = useParams();
    const navigation = useNavigate()
    const {updateFullData} = useContext(RequestContext)
    const [dataLoading, setDataLoading] = useState(true);
    const [requestStatus, setRequestStatus] = useState("");
    const [allowActions, setAllowActions] = useState(true);
    const [type, setType] = useState("")

    useEffect(() => {
        const getRequestContextData = useWebStorageHelper.getItemSession(REQUEST_CONFIG_SESSION_KEY)
        setType(getRequestContextData?.type || "");
        const gteUserData = useWebStorageHelper.getItem(USER_DATA_KEY)
        if(requestId){
            getConfigData(requestId, gteUserData)
        }else if(!isEmpty(getRequestContextData) && !isEmpty(gteUserData)){
            callConfigApi(getRequestContextData , gteUserData)
        }else {
            setShowNotifications(true);
            setNotificationType(WARNING_NOTIFICATION_TYPE);
            setNotificationMessage("No preselected data is present.")
            setTimeout(() => {
                navigation(PLATFORM_DETAILS_PAGE_LINK)
            }, 1000)
        }
    }, [])

    const getConfigData = async (requestId, gteUserData) => {
        const getConfigResponse = await getConfigDetailsByJobID(requestId)
        setRequestStatus(getConfigResponse?.status)
        setType(getConfigResponse?.type)

        if(!isEmpty(getConfigResponse)){
            let sessionData = {
                "ts_channel_id": getConfigResponse?.meta?.ts_channel_id,
                "ss_core_supply_router_id": getConfigResponse?.meta?.ss_core_supply_router_id,
                "platform_amg_id": getConfigResponse?.meta?.platform_amg_id,
                "type": getConfigResponse?.type,
                "status": "review",
                "user_email": gteUserData?.email,
                "creation_type": getConfigResponse?.meta?.creation_type,
                "platform_name": getConfigResponse?.meta?.platform_name,
            }
            const contextData = mapApiDataToContext(getConfigResponse)
            await callConfigApi(sessionData, gteUserData)
            updateFullData(contextData?.generalData, contextData?.onboardingFileUrl, contextData?.otherDetails, contextData?.supplyDetails, contextData?.supplyFormLabelData, getConfigResponse?.comments)
        }
    }

    const callConfigApi = async (contextData = {}, userData = {}) => {
        let formData = []
        let configData = []
        let platformContextData = {}
        let supplyPartnerData = []

        const supplyPartnerResponse = await getAdsPlusAllSupplyPartner(100, 0)
        if(supplyPartnerResponse?.length > 0){
            supplyPartnerData = supplyPartnerResponse.map((data) => (
                {"label": data?.supply_partner_name, "value": data?.supply_partner_id}
            ))
        }
        else {
            setAllowActions(false)
            setShowNotifications(true);
            setNotificationType(WARNING_NOTIFICATION_TYPE);
            setNotificationMessage("No supply partners are available.")
            setTimeout(() => {
                navigation(-1)
            }, 3000)
        }

        if(contextData?.type === NON_TS_PLATFORM_TYPE){
            formData = NonTSRequestGeneralDetailsFormConfig(supplyPartnerData,contextData?.platform_amg_id)
            configData = NonTSotherDetailsFormConfig
            platformContextData = {...contextData, "user_email": userData?.email}
        }

        if(contextData?.type === TS_PLATFORM_TYPE){
            const supplyTagData = await getSupplyTagsByChannelId(contextData?.ts_channel_id, "TS", userData?.email)
            if(supplyTagData?.data?.data?.length > 0){
                const supplyTagOptions = supplyTagData.data.data.map((data) => {
                    return {
                        value: data?.supply_tag_id,
                        label: data?.supply_tag_name,
                    }
                })
                formData = TSRequestGeneralDetailsFormConfig(supplyPartnerData,contextData?.platform_amg_id, supplyTagOptions, contextData?.ts_channel_id , contextData?.platform_name)
                configData = TSOtherDetailsFormConfig
                platformContextData = {...contextData, "user_email": userData?.email}
            }
            else{
                setAllowActions(false);
                setShowNotifications(true);
                setNotificationType(WARNING_NOTIFICATION_TYPE);
                setNotificationMessage("No supply tags are available for this channel.")
                setTimeout(() => {
                    navigation(-1)
                }, 3000)
            }
        }
        setPreLoadData(platformContextData)
        setConfigFormModuleData(configData)
        setGeneralFormModuleData(formData)
        setDataLoading(false)
    }

    const showNotification = (type = "", msg = "") => {
        setShowNotifications(true);
        setNotificationType(type);
        setNotificationMessage(msg)
    }

    const handleCloseNotification = () => {
        setShowNotifications(false);
        setNotificationType("");
        setNotificationMessage("")
    }

    return (
        <React.Fragment>
            <Grid item xs={12} className="heading-grid-request-input">
                Let’s add the details for the delivery
            </Grid>
            <Grid container xs={12} className="request-input-body">
                {
                    dataLoading && <LazyLoading />
                }
                {
                    !dataLoading && (
                        <React.Fragment>
                            {/* General Detail */}
                            <Grid item xs={12} className="general-detail-grid">
                                General Details
                            </Grid>
                            <GeneralRequestForm
                                formConfig={generalFormModuleData}
                                showNotification={showNotification}
                            />
                            {/* Border */}
                            <Grid item xs={12}>
                                <VectorFullWidthIcon />
                            </Grid>
                            {/* Onboarding Details */}
                            <Grid item xs={12} className="general-detail-grid">
                                Onboarding Details
                            </Grid>
                            <OnbConfigFile
                                showNotification={showNotification}
                                OtherformConfig={configFormModuleData}
                                type={type}
                            />
                            <OtherDetails
                                showNotification={showNotification}
                                formConfig={configFormModuleData}
                            />
                            {/* Request Actions */}
                            <RequestActions
                                showNotification={showNotification}
                                metaData={preLoadData}
                                requestId={requestId}
                                requestStatus={requestStatus}
                                allowActions={allowActions}
                            />
                            <Notification
                                message={notificationMessage}
                                handleClose={handleCloseNotification}
                                type={notificationType}
                                open={showNotifications}
                            />
                        </React.Fragment>
                    )
                }
            </Grid>
        </React.Fragment>
    );
}

export default RequestDetailsInput;

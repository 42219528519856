import axios from "axios";
import {ADS_PLUS_DOWNLOAD_SERVICE, ADS_PLUS_SELF_SERVE_TOKEN} from "../Constant";

export const fileUploadApi = (fileData = {}, email = "") => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${ADS_PLUS_DOWNLOAD_SERVICE}/api/v1/upload/onboarding-docs`,
        headers: {
            'x-user-id': email,
            'traceparent': 'source=adsplus',
            'tracestate': 'sample',
            'Authorization': ADS_PLUS_SELF_SERVE_TOKEN
        },
        data : fileData
    };

    return axios.request(config)
        .then((response) => {
            if(response?.data?.data?.file?.length > 0){
                let fileData = {}
                response.data.data.file.map((data) => {
                    fileData[data?.file_type] = data?.file_id
                })

                return fileData
            }

            return []
        })
        .catch((error) => {
            console.log(error);
        });
}
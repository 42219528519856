import axios from "axios";
import {
    AUTH_TOKEN,
    JOB_SUCCESS, LAST_JOB_ID,
    POST_URI,
    SEVERITY_TYPE,
    SUCCESS_TYPE,
    TAG_IS_NOT_UPDATED, TS_TAG_UPDATE_SUCCESSFUL,
    WARNING_TYPE
} from "../Constant";
import {setLocalStorageData} from "../utils/localStorageHelper";
export const updateMacrosToThunderstormBulk = async (platformId, demandTagData, email) => {
    const url = `${POST_URI}${platformId}/ts-tags/bulk`;


    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: AUTH_TOKEN,
            "x-user-id": email,
        },
    };

    try {
        const response = await axios.put(url, demandTagData, config);
        if(response.data?.data?.job_status === JOB_SUCCESS){
            const jobID = response.data?.data?.job_id || ""
            setLocalStorageData(LAST_JOB_ID, false, jobID)
            return {message: response.data.data?.message || TS_TAG_UPDATE_SUCCESSFUL, type: SUCCESS_TYPE};
        }else {
            return {message: TAG_IS_NOT_UPDATED, type: SEVERITY_TYPE}
        }
    } catch (error) {
        if(error?.response?.data?.message){
            return {message: error.response.data.message, type: WARNING_TYPE}
        }else {
            return {message: TAG_IS_NOT_UPDATED, type: SEVERITY_TYPE}
        }
    }
};

import { Grid } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import SimpleInput from "../../Common/SimpleInput";
import DropInput from "../../Common/DropInput";
import AdsSimpleSelect from "../AdsComponent/AdsSimpleSelect";
import AdsSimpleTextInput from "../AdsComponent/AdsSimpleTextInput";
import ContactInnerTable from "../AdsComponent/ContactInnerTable";
import PropTypes from "prop-types";
import { SIMPLE_SELECT_TYPE, TYPE_INPUT, WARNING_NOTIFICATION_TYPE } from "../../Constant";
import useWebStorageHelper from "../../utils/sessionStorageHelper";
import { RequestContext } from "../../Context/RequestContext";
import "./index.css";
import SimpleTelInput from "../../Common/SimpleTelInput";

const GeneralRequestForm = (props) => {
    const [generalFormData, setGeneralFormData] = useState({});
    const [generalFormLabelMap, setGeneralFormLabelMap] = useState({});
    const [fileLoading, setFileLoading] = useState(false);
    const { updateGeneralStateData, generalData } = useContext(RequestContext);
    const [dealType, setDealType] = useState("");

    // Sync the form state with context when `generalData` changes
    useEffect(() => {
        setGeneralFormData(generalData);
        const labelMap = {};

        if (generalData?.deal_type) {
            setDealType(generalData.deal_type);
        } else {
            setDealType("");
        }

        // Iterate through the formConfig to create the label map
        props.formConfig.forEach((field) => {
            if (field.type === SIMPLE_SELECT_TYPE && generalData[field.key]) {
                const selectedOption = field.options.find(
                    (option) => option.value === generalData[field.key]
                );
                labelMap[field.key] = selectedOption ? selectedOption.label : "";
            }
        });
        setGeneralFormLabelMap(labelMap);
    }, [generalData, props.formConfig]);

    const onClickChange = (value = "", index = 0, type = "", key = "", label = "") => {
        const getStateFormFields = { ...generalFormData };
        const getStateGeneralFormLabelMap = { ...generalFormLabelMap };

        if (type === SIMPLE_SELECT_TYPE) {
            getStateGeneralFormLabelMap[key] = label;
            getStateFormFields[key] = value;
            if (key === "deal_type") {
                setDealType(value);
                if (value === "inventory") {
                    getStateFormFields["commission"] = ""; // Reset Commission %
                }
                if (["revenue", "fallback"].includes(value)) {
                    getStateFormFields["ss_core_inventory_share"] = ""; // Reset Ads+ Inv. share %
                }
            }
        } else if (type === TYPE_INPUT) {
            getStateFormFields[key] = parseInt(value);
        } else {
            getStateFormFields[key] = value;
        }

        setGeneralFormData(getStateFormFields);
        setGeneralFormLabelMap(getStateGeneralFormLabelMap);
        updateGeneralStateData(getStateFormFields);
    };

    const onInnerHtmlChange = (key, value) => {
        const getStateFormFields = { ...generalFormData };
        getStateFormFields[key] = value;
        setGeneralFormData(getStateFormFields);
        updateGeneralStateData(getStateFormFields);
    };

    const handleFileChange = async (file, key) => {
        setFileLoading(true);
        const getStateFormFields = { ...generalFormData };

        if (file) {
            const status = await useWebStorageHelper.setFileItem(file, key);
            if (status) {
                setFileLoading(false);
                getStateFormFields[key] = file?.name|| "so_document_file";
                setGeneralFormData(getStateFormFields);
                updateGeneralStateData(getStateFormFields);
            } else {
                props.showNotification(WARNING_NOTIFICATION_TYPE, "Not able to upload file");
                setFileLoading(false);
            }
        } else {
            props.showNotification(WARNING_NOTIFICATION_TYPE, "File Deleted");
            setFileLoading(false);
        }
    };

    return (
        <React.Fragment>
            {props?.formConfig?.map((field, index) => {
                if (
                    (field.key === "commission" && !["revenue", "fallback"].includes(dealType)) ||
                    (field.key === "ss_core_inventory_share" && dealType !== "inventory")
                ) {
                    return null;
                }
                return (
                    <Grid item xs={12} key={index} className="input-container">
                        {field.type === "heading" ? (
                            <div className="general-form-section">{field.label}</div>
                        ) : (
                            <Grid container>
                                <Grid item xs={12} md={1} className="request-form-label-grid">
                                    {field.label} <span className="necessary-label-asterick">{field?.necessary ? "*" : ""}</span>
                                </Grid>
                                <Grid item xs={12} md={10} className="request-form-value-grid">
                                    {field.type === "text" ? (
                                        field.value
                                    ) : field.type === "inner_contact_table" ? (
                                        <ContactInnerTable
                                            formKey={field.key}
                                            onChange={onInnerHtmlChange}
                                            contactsData={generalFormData?.contacts}
                                        />
                                    ) : field.type === "select" ? (
                                        <AdsSimpleSelect
                                            type={field.type}
                                            index={index}
                                            onChange={onClickChange}
                                            options={field.options}
                                            label={field.label}
                                            placeholder={field.placeholder}
                                            formKey={field.key}
                                            value={generalFormData[field?.key] || ""}
                                            fieldValue={generalFormLabelMap[field?.key] || ""}
                                        />
                                    ) : field.type === "input" ? (
                                        <SimpleTelInput
                                            type={field.type}
                                            onChange={onClickChange}
                                            index={index}
                                            textWidth={field.width}
                                            placeholder={field.placeholder}
                                            formKey={field?.key}
                                            postUnit={field.post_unit ? field.post_unit : ""}
                                            value={generalFormData[field?.key] || ""}
                                            preUnit={field.preUnit ? field.preUnit: ""}
                                        />
                                    ) : field.type === "file_input" ? (
                                        <DropInput
                                            supportedType={field.config.supportedType}
                                            fileType={field.config.fileType}
                                            headMessage={field.config.headMessage}
                                            subMessage={field.config.subMessage}
                                            onFileChange={handleFileChange}
                                            type={field.type}
                                            formKey={field.key}
                                            fileLoading={fileLoading}
                                        />
                                    ) : field.type === "text_input" ? (
                                        <AdsSimpleTextInput
                                            type={field.type}
                                            defaultValue={field.value}
                                            onChange={onClickChange}
                                            index={index}
                                            textWidth={field.width}
                                            placeholder={field.placeholder}
                                            formKey={field?.key}
                                            postUnit={field.post_unit ? field.post_unit : ""}
                                            value={generalFormData[field?.key] || field?.value}
                                            textSize={field.textLimit}
                                            disabled={field?.disabled || false}
                                        />
                                    ) : null}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                );
            })}
        </React.Fragment>
    );
};

GeneralRequestForm.propTypes = {
    showNotification: PropTypes.func.isRequired,
    formConfig: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            type: PropTypes.string,
            key: PropTypes.string,
            width: PropTypes.string,
            placeholder: PropTypes.string,
            preUnit: PropTypes.string,
            postUnit: PropTypes.string,
            necessary: PropTypes.bool,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string,
                    value: PropTypes.string,
                })
            ),
        })
    ).isRequired,
};

export default GeneralRequestForm;

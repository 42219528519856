// Use these functions to interact with session storage

const useWebStorageHelper = {
    // Function to set file item in localstorage
    setFileItem: (file, key) => {
        return new Promise((resolve, reject) => {
            // Check if the file size is within `localStorage` limits
            if ( file && file?.size > 5000000) { // 5MB in bytes
                console.log('Provided file is more than 5000');
                reject(false);
                return;
            }


            const reader = new FileReader();

            if (file && !(file instanceof Blob)) {
                console.log('Provided file is not a Blob or File object');
                reject("Provided file is not a Blob or File object");
                return;
            }else {
                reader?.readAsDataURL(file);
            }


            reader.onload = () => {
                const base64StringFile = reader.result;
                try {
                    localStorage.setItem(key, base64StringFile); // Store in localStorage
                    resolve(true); // Resolve the promise with true on success
                } catch (error) {
                    console.log('Error loading:', error);
                    reject("Error loading the file");
                }
            };

            reader.onerror = (error) => {
                console.log('Error converting file to Base64:', error);
                reject("Error converting file to Base64"); // Reject the promise with false on failure
            };
        });
    },

    // Function to set item in localStorage
    setItem: (key, value) => {
        try {
            const serializedValue = JSON.stringify(value);
            localStorage.setItem(key, serializedValue);
        } catch (error) {
            console.error("Error saving to localStorage", error);
        }
    },

    // Function to set item in localStorage
    setItemSession: (key, value) => {
        try {
            const serializedValue = JSON.stringify(value);
            sessionStorage.setItem(key, serializedValue);
        } catch (error) {
            console.error("Error saving to localStorage", error);
        }
    },

    // Function to get item from localStorage
    getItem: (key) => {
        try {
            const serializedItem = localStorage.getItem(key);
            return serializedItem ? JSON.parse(serializedItem) : null;
        } catch (error) {
            console.error("Error reading from localStorage", error);
            return null;
        }
    },

    getFileItem: (key, filename, content_type) => {
        try {
            const fileString = localStorage.getItem(key);
            if(fileString){
                const byteString = atob(fileString?.split(',')?.[1]); // Remove base64 header and decode
                const ab = new ArrayBuffer(byteString?.length);
                const ia = new Uint8Array(ab);

                for (let i = 0; i < byteString?.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                const blob = new Blob([ab], { type: content_type });
                const file = new File([blob], filename, { type: content_type });
                return file;
            }

            return null
        }catch (err){
            console.error("Error reading from localStorage", err);
            return null;
        }
    },

    setItemSessionWithExpiry: (key, value, ttl) => {
        try {
            const now = new Date();
            const item = {
                value: value,
                expiry: now.getTime() + ttl
            }
            sessionStorage.setItem(key, JSON.stringify(item));
        } catch (error) {
            console.error("Error saving to localStorage", error);
        }
    },

    getItemSessionWithExpiry: (key) => {
        try {
            const itemStr = sessionStorage.getItem(key);
            if (!itemStr) {
                return null;
            }
            const item = JSON.parse(itemStr);
            const now = new Date();
            if (now.getTime() > item.expiry) {
                sessionStorage.removeItem(key);
                return null;
            }
            return item.value;
        } catch (error) {
            console.error("Error reading from localStorage", error);
            return null;
        }
    },

    // Function to get item from sessionStorage
    getItemSession: (key) => {
        try {
            const serializedItem = sessionStorage.getItem(key);
            return serializedItem ? JSON.parse(serializedItem) : null;
        } catch (error) {
            console.error("Error reading from localStorage", error);
            return null;
        }
    },

    // Function to remove item from localStorage
    removeItem: (key) => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error("Error removing item from localStorage", error);
        }
    },

    // Function to remove item from localStorage
    removeItemSession: (key) => {
        try {
            sessionStorage.removeItem(key);
        } catch (error) {
            console.error("Error removing item from localStorage", error);
        }
    },

    removeOnbFile: ()=> {
        try{
            localStorage.removeItem("onb_config_details_file_url");
            localStorage.removeItem("onb_supply_details_file_url");
            localStorage.removeItem("so_document_file_url");
            localStorage.removeItem("ad_domain_blocklist_file_url")
            localStorage.removeItem("link_to_sale_collateral")
        }
        catch (e){
            console.error("Error removing item from localStorage", e)
        }
    },

    // Function to clear all localStorage
    clear: () => {
        try {
            localStorage.clear();
        } catch (error) {
            console.error("Error clearing localStorage", error);
        }
    }
};

export default useWebStorageHelper;

import "./index.css";
import { useState, useContext, useEffect, useRef } from "react";
import React from "react";
import DropIcon from "../../assests/icons/dropIcon";
import EllipseIcon from "../../assests/icons/ellipseIcon";
import PinIcon from "../../assests/icons/pinIcon";
import DeleteIcon from "../../assests/icons/deleteIcon";
import PropTypes from "prop-types";
import { RequestContext } from "../../Context/RequestContext";
import {TextField} from "@mui/material";
import {isEmpty} from "../../utils/helper";

const DropInputOrLink = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const { otherDetails } = useContext(RequestContext);
    const fileInputRef = useRef(null);
    // Load the file from context if it exists
    useEffect(() => {
        if(props.formKey === "link_to_sale_collateral" && !isEmpty(otherDetails?.sales_collateral_file_url) ){
            const existingFile = {
                name: otherDetails?.sales_collateral_file_url?.split('/').pop(),
                url: otherDetails?.sales_collateral_file_url
            }
            setSelectedFile(existingFile);
        }
        if(props.formKey === "ad_domain_blocklist_file_url" && !isEmpty(otherDetails?.ad_domain_blocklist_file_url) ){
            const existingFile = {
                name: otherDetails?.ad_domain_blocklist_file_url?.split('/').pop(),
                url: otherDetails?.ad_domain_blocklist_file_url
            }
            setSelectedFile(existingFile);
        }
    }, [otherDetails?.sales_collateral_file_url, otherDetails?.ad_domain_blocklist_file_url]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            if (props?.onFileChange) {
                props.onFileChange(file, props.formKey);
            }
        }
    };

    const handleDropAreaClick = () => {
        fileInputRef.current.click();
    };

    const handleDeleteClick = () => {
        setSelectedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        if (props.onFileChange) {
            props.onFileChange("", props.formKey);
        }
    };

    const handleChange = (e) => {
        if(selectedFile){
            setSelectedFile(null);
        }
        const value = e?.target?.value || "";
        props.onChange(value, props.index, props.type, props.formKey);
    }

    return (
        <React.Fragment>
            {props.linkField && (
                <React.Fragment>
                    {props?.preUnit && <span className="pre-unit-simple-input">{props.preUnit}</span>}
                    <TextField
                        variant="standard"
                        placeholder={props?.placeholder || "Enter the value"}
                        size="small"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        value={props?.value}
                        className="number-simple-input"
                        style={{width: props?.textWidth || "100%"}}
                        inputProps={{ maxLength: props?.textSize || 200 }}
                        helperText={props?.textSize ? `${props.value.length}/${props.textSize}` : null}
                        disabled={props?.disabled}
                    />
                    {props.postUnit && ( <span className="post-unit-simple-input">{props.postUnit}</span>)}
                    <div className="sales-collateral-option-text">
                        or upload a PDF
                    </div>
                </React.Fragment>
            )}
            <div className="drop-input-div">
                <div className="drop-div">
                    {props?.headMessage && (
                        <div className="head-message">
                            {props.headMessage}
                        </div>
                    )}
                    <div className="drop-area-div" onClick={handleDropAreaClick}>
                        <DropIcon />
                        <div className="drop-span">
                            <span className="drop-typography">Drop your files here or
                                <span style={{ color: "#7FB8F0", cursor: "pointer" }}> browse
                                </span>
                            </span>
                            <div className="drop-sub-typography">
                                Up to 1MB <EllipseIcon />
                                {props?.fileType}
                            </div>
                        </div>
                        <input
                            id="file-upload-input"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            accept={props.supportedType}
                            ref={fileInputRef}
                        />
                    </div>
                    <div className="drop-subhead">
                        {props.download && <span className="drop-subhead-typography">
                            {props?.subMessage} <span style={{ color: "#7FB8F0" }}>Download sample</span> here.
                        </span>}
                    </div>
                </div>
                {selectedFile && (
                    <div className="dropped-file-div-od">
                        <div className="pin-dropped-div-od">
                            <PinIcon />
                            {selectedFile.name}
                        </div>
                        <div className="delete-icon" onClick={handleDeleteClick}>
                            <DeleteIcon />
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

DropInputOrLink.propTypes = {
    fileType: PropTypes.string.isRequired,
    onFileChange: PropTypes.func.isRequired,
    headMessage: PropTypes.string,
    subMessage: PropTypes.string,
    supportedType: PropTypes.string.isRequired,
    formKey: PropTypes.string.isRequired,
    fileLoading: PropTypes.bool,
    download: PropTypes.bool,
    linkField: PropTypes.bool
};

export default DropInputOrLink;

import {jobDetails} from "../__mock__/jobDetials";
import axios from 'axios';
import {AUDIT_AUTH_TOKEN,AUDIT_TRAIL_URL} from "../Constant";
const getJobStatusById = async (jobId) => {
    try {
        const response = await axios.get(`${AUDIT_TRAIL_URL}/jobs/${jobId}`, {
            headers: {
                'accept': 'application/json',
                'Authorization': AUDIT_AUTH_TOKEN
            }
        });
        return response.data;
    } catch (error) {
        console.log("error while fetching jobDetails")

    }
};

export default getJobStatusById;

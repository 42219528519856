import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getChannelDetails = async (id = "", email = "", type = "") => {
    const data = JSON.stringify({
        query: `query MyQuery($id: String = "", $type: String = "") {
            getChannelDetails(id: $id, type: $type) {
                channel_id
                created_at
                name
                platform_id
                policy
                supply_partner_id
                supply_partner_name
                supply_router_id
                supply_router_url
                ts_tags
                updated_at
            }
        }`,
        variables: { "id": id, "type": type }
    });
    const headers = adsHeader()

    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    try {
        const response = await axios.request(config);
        if (response?.data?.data?.getChannelDetails && response.status === 200) {
            return response.data.data.getChannelDetails;
        } else {
            return {};
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};

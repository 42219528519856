import React, {useContext} from "react";
import {Button} from "@mui/material";
import {RequestContext} from "../../../Context/RequestContext";
import PropTypes from "prop-types";
import transformData from "../../../FormConfig/FormatRequestData";
import useWebStorageHelper from "../../../utils/sessionStorageHelper";
import {fileUploadApi} from "../../../api/fileUploadApi";
import {isEmpty, isFieldEmpty, removeCircularObjects} from "../../../utils/helper";
import {
    DATA_IN_LOADING_STATE,
    DEVICE_PERCENTAGE_NOT_EQUAL_100,
    ENTER_REQUIRED_DETAILS,
    ERROR_NOTIFICATION_TYPE,
    FILE_UPLOAD_FAILURE_MSG,
    GEO_PERCENTAGE_NOT_EQUAL_100,
    REQUEST_CONFIG_SESSION_KEY,
    REQUESTS_LISTING_PAGE_LINK,
    SUCCESS_NOTIFICATION_TYPE,
    WARNING_NOTIFICATION_TYPE,
    MINIMUM_SUPPLY_TAG_ERROR,
    ALL_KEYS_IN_APP_SUPPLY_SHOULD_HAVE_VALUES,
    NON_TS_PLATFORM_TYPE,
    TS_GENERAL_NECESSARY_FIELD,
    TS_OTHER_NECESSARY_FIELD,
    REQUEST_STATUS_DRAFT,
    REQUEST_STATUS_REVIEW,
    REQUEST_STATUS_SUBMIT, REQUEST_STATUS_CREATE, FILE_UPLOAD_SUCCESS_MSG
} from "../../../Constant";
import {createAdsPlusRequest} from "../../../api/createAdsPlusRequest";
import CircularProgress from '@mui/material/CircularProgress';
import {useNavigate} from "react-router-dom";
import {GENERAL_NECESSARY_FIELD, OTHER_NECESSARY_FIELD} from "../../../Constant";
import {draftAdsPlusRequest} from "../../../api/draftAdsPlusRequest";

function RequestActions (props){
    const {
        generalData,
        onboardingFileUrl,
        otherDetails,
        supplyDetails,
        commentsData,
        resetState
    } = useContext(RequestContext)
    const navigation = useNavigate();
    const [loading, setLoading] = React.useState(false);

    const onClickSubmit = async () => {
        setLoading(true)
        try {
            // TODO add file name in constant. and type also
            let formData = new FormData();
            const getOnbConfigFile = useWebStorageHelper.getFileItem("onb_config_details_file_url", "onb_config_file.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            const getSupplyFile = useWebStorageHelper.getFileItem("onb_supply_details_file_url", "onb_supply_file.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            const getSoFile = useWebStorageHelper.getFileItem("so_document_file_url", "so_document_file.pdf", "application/pdf")
            const getBlockListFile = useWebStorageHelper.getFileItem("ad_domain_blocklist_file_url", "ad_domain_blocklist_file_url.csv", "text/csv");
            const getSalesCollateralLink = useWebStorageHelper.getFileItem("link_to_sale_collateral", "sales_collateral.pdf", "application/pdf");

            let fieldMissingData = ""
            let generalRequiredFields = props?.metaData?.type === NON_TS_PLATFORM_TYPE ? GENERAL_NECESSARY_FIELD : TS_GENERAL_NECESSARY_FIELD
            let otherRequiredFields = props.metaData.type === NON_TS_PLATFORM_TYPE ? OTHER_NECESSARY_FIELD : TS_OTHER_NECESSARY_FIELD
            const allGeneralFieldsFilled = generalRequiredFields.every(field => {
                const value = generalData[field.key];
                if (isFieldEmpty(value)) {
                    fieldMissingData = `${field.label} is empty`
                    return false;
                }
                return true;
            });

            if (!allGeneralFieldsFilled && fieldMissingData !== "") {
                props.showNotification(WARNING_NOTIFICATION_TYPE, fieldMissingData);
                return;
            }

            const dealType = generalData.deal_type;
            if (dealType === "inventory" && isFieldEmpty(generalData.ss_core_inventory_share)) {
                fieldMissingData = "Ads+ Inv. share % is empty";
            } else if ((dealType === "revenue" || dealType === "fallback") && isFieldEmpty(generalData.commission)) {
                fieldMissingData = "Commission % is empty";
            }

            if (!allGeneralFieldsFilled || fieldMissingData !== "") {
                props.showNotification(WARNING_NOTIFICATION_TYPE, fieldMissingData);
                return;
            }

            const allOtherFieldsFilled = otherRequiredFields.every(field => {
                const value = otherDetails[field.key];
                if (isFieldEmpty(value)) {
                    fieldMissingData = `${field.label} is empty`
                    return false;
                }
                return true;
            });

            if (! allOtherFieldsFilled && fieldMissingData !== "") {
                props.showNotification(WARNING_NOTIFICATION_TYPE, fieldMissingData)
                return;
            }
            const deviceTypeData = otherDetails.device_type || [];
            const totalDevicePercentage = deviceTypeData.reduce((sum, item) => sum + (item.percentage || 0), 0);
            if(generalData?.so_document_file_url === "" && getSoFile === null ){
                props.showNotification(WARNING_NOTIFICATION_TYPE, "SO Document is not attached")
                return;
            }

            if (totalDevicePercentage !== 100) {
                props.showNotification(WARNING_NOTIFICATION_TYPE, DEVICE_PERCENTAGE_NOT_EQUAL_100);
                return;
            }
            const GeoData = otherDetails.inventory_available_in_geo || [];
            const totalGeoPercentage = GeoData.reduce((sum, item) => sum + parseInt(item.percentage || 0, 10), 0);


            if (totalGeoPercentage !== 100) {
                props.showNotification(WARNING_NOTIFICATION_TYPE, GEO_PERCENTAGE_NOT_EQUAL_100);
                return;
            }

            if(supplyDetails?.supply_tags?.length === 0 ){
                props.showNotification(WARNING_NOTIFICATION_TYPE, MINIMUM_SUPPLY_TAG_ERROR);
                return;
            }else if(supplyDetails?.supply_tags?.length > 0 ){
                const allSupplyTagsFilled = supplyDetails.supply_tags.every(tag => {
                    return Object.values(tag).every(value => !isFieldEmpty(value));
                });

                if (!allSupplyTagsFilled) {
                    props.showNotification(WARNING_NOTIFICATION_TYPE, ALL_KEYS_IN_APP_SUPPLY_SHOULD_HAVE_VALUES);
                    return;
                }
            }else if(!supplyDetails?.supply_tags){
                props.showNotification(WARNING_NOTIFICATION_TYPE, MINIMUM_SUPPLY_TAG_ERROR);
                return;
            }

            if(otherDetails?.ad_quality_requirements === "ad_domain_blocklist" && isEmpty(otherDetails?.ad_domain_blocklist_file_url)){
                props.showNotification(WARNING_NOTIFICATION_TYPE, "Blocklist file is not attached")
                return;
            }
            if(generalData?.channel_name && generalData?.ads_plus_supply_partner_id && props?.metaData?.status === "create" && props?.metaData?.user_email){
                getOnbConfigFile !== null && formData.append("config_file", getOnbConfigFile, "onb_config_file.xlsx");
                getSupplyFile !== null && formData.append("supply_file", getSupplyFile, "onb_supply_file.xlsx");
                getSoFile !== null && formData.append("so_file", getSoFile, "so_document_file.pdf");
                getBlockListFile !== null && formData.append("ad_domain_blocklist_file", getBlockListFile, "blocklist_file.csv");
                getSalesCollateralLink !== null && formData.append("sales_collateral_file", getSalesCollateralLink, "sales_collateral_file.pdf");

                formData.append("channel_id", generalData?.channel_name)

                let fileResponse = {};
                if(getOnbConfigFile || getSupplyFile || getSoFile || getBlockListFile || getSalesCollateralLink){
                    fileResponse = await fileUploadApi(formData, props.metaData.user_email);
                    if (isEmpty(fileResponse)) {
                        props.showNotification(ERROR_NOTIFICATION_TYPE, FILE_UPLOAD_FAILURE_MSG);
                    } else {
                        props.showNotification(SUCCESS_NOTIFICATION_TYPE, FILE_UPLOAD_SUCCESS_MSG);
                    }
                }

                if(!isEmpty(fileResponse)){
                    const getFinalState = {...generalData, ...onboardingFileUrl, ...otherDetails, ...supplyDetails, ...props.metaData}
                    const apiData = removeCircularObjects(transformData(getFinalState, fileResponse["so_file"], fileResponse["config_file"], fileResponse["supply_file"], false, "", [],  fileResponse["ad_domain_blocklist_file"], fileResponse["sales_collateral_file"]));

                    const createResponse = await createAdsPlusRequest(apiData)
                    if(createResponse && createResponse !== "Wrong details entered"){
                        useWebStorageHelper.removeItemSession(REQUEST_CONFIG_SESSION_KEY)
                        useWebStorageHelper.removeItem("onb_config_details_file_url")
                        useWebStorageHelper.removeItem("onb_supply_details_file_url")
                        useWebStorageHelper.removeItem("so_document_file_url")
                        useWebStorageHelper.removeItem("ad_domain_blocklist_file_url");
                        useWebStorageHelper.removeItem("link_to_sale_collateral");
                        props.showNotification(SUCCESS_NOTIFICATION_TYPE, `We submit your request job : ${createResponse}`)
                        setTimeout(() => {
                            navigation(REQUESTS_LISTING_PAGE_LINK)
                        }, 1000)
                        resetState()
                    }else {
                        props.showNotification(WARNING_NOTIFICATION_TYPE, ENTER_REQUIRED_DETAILS)
                    }
                }else {
                    props.showNotification(ERROR_NOTIFICATION_TYPE, FILE_UPLOAD_FAILURE_MSG)
                }
            }else if(generalData?.channel_name && generalData?.ads_plus_supply_partner_id && (props?.metaData?.status === REQUEST_STATUS_DRAFT || props?.metaData?.status === REQUEST_STATUS_REVIEW) && props?.metaData?.user_email) {
                getOnbConfigFile !== null && formData.append("config_file", getOnbConfigFile, "onb_config_file.xlsx");
                getSupplyFile !== null && formData.append("supply_file", getSupplyFile, "onb_supply_file.xlsx");
                getSoFile !== null && formData.append("so_file", getSoFile, "so_document_file.pdf");
                getBlockListFile !== null && formData.append("ad_domain_blocklist_file", getBlockListFile, "blocklist_file.csv");
                getSalesCollateralLink !== null && formData.append("sales_collateral_file", getSalesCollateralLink, "sales_collateral_file.pdf");

                formData.append("channel_id", generalData?.channel_name)
                let apiData = {}
                const getFinalState = {...generalData, ...onboardingFileUrl, ...otherDetails, ...supplyDetails, ...props.metaData}

                if(getOnbConfigFile !== null || getSupplyFile !== null || getSoFile !== null || getBlockListFile !== null || getSalesCollateralLink !== null){
                    const fileResponse = await fileUploadApi(formData, props.metaData.user_email)
                    if(!isEmpty(fileResponse)){
                        apiData = removeCircularObjects(transformData(getFinalState, fileResponse["so_file"], fileResponse["config_file"], fileResponse["supply_file"], true, props.requestId ,commentsData, fileResponse["ad_domain_blocklist_file"], fileResponse["sales_collateral_file"]))
                    }else {
                        props.showNotification(ERROR_NOTIFICATION_TYPE, FILE_UPLOAD_FAILURE_MSG)
                    }
                }else {
                    apiData = removeCircularObjects(transformData(getFinalState, generalData["so_document_file_url"], onboardingFileUrl["onb_config_details_file_url"], onboardingFileUrl["onb_supply_details_file_url"], true, props.requestId ,commentsData))
                }
                const createResponse = await createAdsPlusRequest(apiData)
                if(createResponse && createResponse !== "Wrong details entered"){
                    useWebStorageHelper.removeItemSession(REQUEST_CONFIG_SESSION_KEY)
                    useWebStorageHelper.removeItem("onb_config_details_file_url")
                    useWebStorageHelper.removeItem("onb_supply_details_file_url")
                    useWebStorageHelper.removeItem("so_document_file_url")
                    useWebStorageHelper.removeItem("ad_domain_blocklist_file_url");
                    useWebStorageHelper.removeItem("link_to_sale_collateral");
                    props.showNotification(SUCCESS_NOTIFICATION_TYPE, `We submit your request job : ${createResponse}`)
                    setTimeout(() => {
                        navigation(REQUESTS_LISTING_PAGE_LINK)
                    }, 1000)
                    resetState()
                }else {
                    props.showNotification(WARNING_NOTIFICATION_TYPE, ENTER_REQUIRED_DETAILS)
                }
            }else {
                props.showNotification(WARNING_NOTIFICATION_TYPE, ENTER_REQUIRED_DETAILS)
            }
        }
        catch (error) {
            props.showNotification(ERROR_NOTIFICATION_TYPE, DATA_IN_LOADING_STATE)
        }
        finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const onClickSaveAsDraft = async () => {
        setLoading(true);
        try {
            let formData = new FormData();
            const getOnbConfigFile = useWebStorageHelper.getFileItem("onb_config_details_file_url", "onb_config_file.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
            const getSupplyFile = useWebStorageHelper.getFileItem("onb_supply_details_file_url", "onb_supply_file.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
            const getSoFile = useWebStorageHelper.getFileItem("so_document_file_url", "so_document_file.pdf", "application/pdf");
            const getBlockListFile = useWebStorageHelper.getFileItem("ad_domain_blocklist_file_url", "ad_domain_blocklist_file_url.csv", "text/csv");
            const getSalesCollateralLink = useWebStorageHelper.getFileItem("link_to_sale_collateral", "sales_collateral.pdf", "application/pdf");

            // Append files to FormData if they exist
            if (getOnbConfigFile) {
                formData.append("config_file", getOnbConfigFile, "onb_config_file.xlsx");
            }
            if (getSupplyFile) {
                formData.append("supply_file", getSupplyFile, "onb_supply_file.xlsx");
            }
            if (getSoFile) {
                formData.append("so_file", getSoFile, "so_document_file.pdf");
            }
            if (getBlockListFile) {
                formData.append("ad_domain_blocklist_file", getBlockListFile, "blocklist_file.csv");
            }
            if (getSalesCollateralLink) {
                formData.append("sales_collateral_file", getSalesCollateralLink, "sales_collateral_file.pdf");
            }

            formData.append("channel_id", generalData?.channel_name);

            if(isEmpty(generalData?.channel_name)){
                props.showNotification(WARNING_NOTIFICATION_TYPE, "Channel name is required")
                return;
            }

            // Upload files if any are present
            let fileResponse = {};
            if (getOnbConfigFile || getSupplyFile || getSoFile || getBlockListFile || getSalesCollateralLink) {
                fileResponse = await fileUploadApi(formData, props.metaData.user_email);
                if (isEmpty(fileResponse)) {
                    props.showNotification(ERROR_NOTIFICATION_TYPE, FILE_UPLOAD_FAILURE_MSG);
                }
                else {
                    props.showNotification(SUCCESS_NOTIFICATION_TYPE, FILE_UPLOAD_SUCCESS_MSG);
                }
            }

            // Prepare final data to be sent to API
            const getFinalState = { ...generalData, ...onboardingFileUrl, ...otherDetails, ...supplyDetails, ...props.metaData };
            const edit = !!props.requestId;
            const apiData = removeCircularObjects(transformData(getFinalState, fileResponse["so_file"], fileResponse["config_file"], fileResponse["supply_file"], edit, props.requestId, commentsData, fileResponse["ad_domain_blocklist_file"], fileResponse["sales_collateral_file"]));
            apiData.createAdsPlusConfig.status = REQUEST_STATUS_DRAFT;

            const createDraftResponse = await draftAdsPlusRequest(apiData);

            if (createDraftResponse && createDraftResponse !== "Wrong details entered") {
                // Cleanup and show success notification
                useWebStorageHelper.removeItemSession(REQUEST_CONFIG_SESSION_KEY);
                useWebStorageHelper.removeItem("onb_config_details_file_url");
                useWebStorageHelper.removeItem("onb_supply_details_file_url");
                useWebStorageHelper.removeItem("so_document_file_url");
                useWebStorageHelper.removeItem("ad_domain_blocklist_file_url");
                useWebStorageHelper.removeItem("link_to_sale_collateral");

                props.showNotification(SUCCESS_NOTIFICATION_TYPE, `Draft saved with request_job_id: ${createDraftResponse}`);
                setTimeout(() => {
                    navigation(REQUESTS_LISTING_PAGE_LINK);
                }, 1000);
            } else {
                props.showNotification(WARNING_NOTIFICATION_TYPE, DATA_IN_LOADING_STATE);
            }
        } catch (error) {
            props.showNotification(ERROR_NOTIFICATION_TYPE, DATA_IN_LOADING_STATE);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const onClickCancel = () => {
        useWebStorageHelper.removeItemSession(REQUEST_CONFIG_SESSION_KEY)
        useWebStorageHelper.removeItem("onb_config_details_file_url")
        useWebStorageHelper.removeItem("onb_supply_details_file_url")
        useWebStorageHelper.removeItem("so_document_file_url")
        useWebStorageHelper.removeItem("ad_domain_blocklist_file_url")
        useWebStorageHelper.removeItem("link_to_sale_collateral")
        resetState()
        props.showNotification(SUCCESS_NOTIFICATION_TYPE, `We will cancel your request.`)
        navigation(-1);
    }


    return(
        <div className="request-form-actions">
            {!loading && props?.allowActions && (
                <React.Fragment>
                    <Button onClick={onClickCancel} type="submit" size="small" variant="outlined" className="cancel-button"> Cancel </Button>
                    {( props?.requestStatus !== REQUEST_STATUS_SUBMIT && props?.requestStatus !== REQUEST_STATUS_REVIEW && props.requestStatus !== REQUEST_STATUS_CREATE ) && (
                        <Button onClick={onClickSaveAsDraft} type="submit" size="small" variant="contained" className="draft-button"> Save as draft </Button>
                    )}
                    {props?.requestStatus !== REQUEST_STATUS_SUBMIT && (
                        <Button onClick={onClickSubmit} type="submit" size="small" variant="contained" className="orange-button"> Send request </Button>
                    )}
                </React.Fragment>
            )}
            {loading && <CircularProgress size={20} disableShrink/>}
        </div>
    )
}

RequestActions.prototype = {
    metaData: PropTypes.shape({
        "ts_channel_id": PropTypes.string,
        "ss_core_supply_router_id": PropTypes.string,
        "platform_amg_id": PropTypes.string.isRequired,
        "creation_type": PropTypes.string.isRequired,
        "platform_name": PropTypes.string.isRequired,
        "type": PropTypes.string.isRequired,
        "status": PropTypes.string.isRequired,
        "user_email": PropTypes.string.isRequired,
        "requestId": PropTypes.string
    })
}

export default RequestActions
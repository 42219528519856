import axios from 'axios';
import {VALIDATE_URI, AUDIT_AUTH_TOKEN, AUTH_TOKEN} from "../Constant";

const getJobValidation = async ( jobId,email) => {
    try {
        const response = await axios.get(
            `${VALIDATE_URI}rollback/bulk/job/${jobId}/validate`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': AUTH_TOKEN,
                    "x-user-id": email,
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error Validating Job :', error);
        throw error; // Re-throw the error for handling in the caller function
    }
};

export default getJobValidation;

import axios from 'axios';
import {VALIDATE_URI, AUTH_TOKEN} from "../Constant";

const putRevertJob = async (jobId, email, role) => {
    try {
        const response = await axios.put(
            `${VALIDATE_URI}rollback/bulk/job/${jobId}`,
            null, // Pass null as the request body since you don't have one
            {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': AUTH_TOKEN,
                    "x-user-id": email,
                    "x-user-role": role
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error In Rollback Job :', error);
        throw error; // Re-throw the error for handling in the caller function
    }
};

export default putRevertJob;

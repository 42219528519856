import React from 'react';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import './index.css';
import {DEVICE_DISTRIBUTION_MAP, INVENTORY_RELATIONS_SHIP_MAP} from "../../../Constant";

function TableAppSupplyDetails(props) {
    return (
        <React.Fragment>
            <React.Fragment>
                <Grid item xs={12} className="app-supply-details-table-frame">
                    <Table className="off-white-table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" size="medium">
                                    INV. RELATIONSHIP
                                </TableCell>
                                <TableCell component="th" scope="row" size="medium">
                                    APP NAME IN APP STORE
                                </TableCell>
                                <TableCell component="th" scope="row" size="medium">
                                    CHANNEL NAME/STREAM
                                </TableCell>
                                <TableCell component="th" scope="row" size="medium">
                                    DEVICE/PLATFORM
                                </TableCell>
                                <TableCell component="th" scope="row" size="medium">
                                    APP BUNDLE ID
                                </TableCell>
                                <TableCell component="th" scope="row" size="medium">
                                    SUPPLY LABEL
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="request-table-body">
                            <React.Fragment>
                                {props?.tableData?.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='table-cell'>{INVENTORY_RELATIONS_SHIP_MAP[data.inventory_relationship]}</TableCell>
                                        <TableCell className='table-cell'>{data.app_name_in_app_store}</TableCell>
                                        <TableCell className='table-cell'>{data.channel_name_stream}</TableCell>
                                        <TableCell className='table-cell'>{DEVICE_DISTRIBUTION_MAP[data.device_type]}</TableCell>
                                        <TableCell className='table-cell'>{data.app_bundle_id}</TableCell>
                                        <TableCell className='table-cell'>{data.supply_label_id}</TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        </TableBody>
                    </Table>
                </Grid>
            </React.Fragment>
        </React.Fragment>
    )
}

export default TableAppSupplyDetails;
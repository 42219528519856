export const getLanguage = [
    {
        "label": "Russian",
        "value": "Russian"
    },
    {
        "label": "Thai",
        "value": "Thai"
    },
    {
        "label": "Dutch",
        "value": "Dutch"
    },
    {
        "label": "Italian",
        "value": "Italian"
    },
    {
        "label": "Belarusian",
        "value": "Belarusian"
    },
    {
        "label": "Esperanto",
        "value": "Esperanto"
    },
    {
        "label": "Estonian",
        "value": "Estonian"
    },
    {
        "label": "Greek",
        "value": "Greek"
    },
    {
        "label": "Latin",
        "value": "Latin"
    },
    {
        "label": "Lithuanian",
        "value": "Lithuanian"
    },
    {
        "label": "Romanian",
        "value": "Romanian"
    },
    {
        "label": "Afrikaans",
        "value": "Afrikaans"
    },
    {
        "label": "Latvian",
        "value": "Latvian"
    },
    {
        "label": "Malayalam",
        "value": "Malayalam"
    },
    {
        "label": "Mongolian",
        "value": "Mongolian"
    },
    {
        "label": "Czech",
        "value": "Czech"
    },
    {
        "label": "Bulgarian",
        "value": "Bulgarian"
    },
    {
        "label": "Hebrew",
        "value": "Hebrew"
    },
    {
        "label": "Icelandic",
        "value": "Icelandic"
    },
    {
        "label": "Turkish",
        "value": "Turkish"
    },
    {
        "label": "Catalan",
        "value": "Catalan"
    },
    {
        "label": "Ukrainian",
        "value": "Ukrainian"
    },
    {
        "label": "Danish",
        "value": "Danish"
    },
    {
        "label": "Vietnamese",
        "value": "Vietnamese"
    },
    {
        "label": "Arabic",
        "value": "Arabic"
    },
    {
        "label": "Finnish",
        "value": "Finnish"
    },
    {
        "label": "Hindi",
        "value": "Hindi"
    },
    {
        "label": "Tagalog",
        "value": "Tagalog"
    },
    {
        "label": "Hungarian",
        "value": "Hungarian"
    },
    {
        "label": "Norwegian",
        "value": "Norwegian"
    },
    {
        "label": "Polish",
        "value": "Polish"
    },
    {
        "label": "Swedish",
        "value": "Swedish"
    },
    {
        "label": "Persian",
        "value": "Persian"
    },
    {
        "label": "Serbian",
        "value": "Serbian"
    },
    {
        "label": "Slovak",
        "value": "Slovak"
    },
    {
        "label": "Albanian",
        "value": "Albanian"
    },
    {
        "label": "Chinese",
        "value": "Chinese"
    },
    {
        "label": "Portuguese",
        "value": "Portuguese"
    },
    {
        "label": "German",
        "value": "German"
    },
    {
        "label": "French",
        "value": "French"
    },
    {
        "label": "Japanese",
        "value": "Japanese"
    },
    {
        "label": "Korean",
        "value": "Korean"
    },
    {
        "label": "Spanish",
        "value": "Spanish"
    },
    {
        "label": "Other",
        "value": "Other"
    },
    {
        "label": "English",
        "value": "English"
    },
    {
        "label": "Multiple Languages",
        "value": "Multiple Languages"
    }
]
import {isEmpty} from "../utils/helper";

export default function transformData(input, soFileUrl = "", configFileUrl = "", supplyFileUrl = "", edit = false, request_job_id = "", commentsData = [], blocklistFileUrl = "", salesCollateralFileLink="") {
    const getContactDefaultValue = () => {
        return [
            {
                type: "partner_primary_poc",
                label: "Partner Primary POC",
                full_name: "",
                email_id: ""
            },
            {
                type: "project_lead",
                label: "Project Lead",
                full_name: "",
                email_id: ""
            },
            {
                type: "ad_ops_contact",
                label: "Ad Ops Contact",
                full_name: "",
                email_id: ""
            }
        ]
    }

    if(edit){
        return {
            createAdsPlusConfig: {
                config: {
                    customer_details: {
                        customer_name: input?.customer_name || "",
                        channel_name: input?.channel_name || "",
                        contacts: input?.contacts?.length > 0 ? input.contacts :  getContactDefaultValue()
                    },
                    platform_name: input?.platform_name || "",
                    amg_id: input?.platform_amg_id || "",
                    ts_stream_id: input?.ts_channel_id || "",
                    deal_type: input?.deal_type || "",
                    ss_core_owner_supply_tag_id: input?.ss_core_owner_supply_tag_id || 0,
                    ss_core_inventory_share: input?.ss_core_inventory_share || 0,
                    net_floor_expectations: input?.net_floor_expectations || "",
                    floor_rate: input?.floor_rate || "",
                    floor_rate_currency: "$",
                    so_document_file_url: !isEmpty(soFileUrl) ? soFileUrl : input.so_document_file_url || "",
                    additional_info_txt: input?.additional_info_txt || "",
                    ads_plus_supply_partner_id: input?.ads_plus_supply_partner_id || "",
                    connection_type: input?.connection_type || "",
                    commission: input?.commission || "",
                    ads_plus_prioritization: input?.ads_plus_prioritization || "",
                    onboarding_details: {
                        label: "Onboarding Details",
                        onb_config_details_file_url: configFileUrl,
                        onb_supply_details_file_url: supplyFileUrl,
                        supply_details: {
                            label: "Supply Description",
                            content_language: input?.content_language || "",
                            content_descriptions: input?.content_descriptions || "",
                            avg_pod_durations_in_sec: input?.avg_pod_durations_in_sec || 0,
                            device_type: input?.device_type?.length > 0 ? input.device_type : [],
                            inventory_available_in_geo: input?.inventory_available_in_geo?.length > 0 ? input.inventory_available_in_geo : [],
                            ad_placement_type: input?.ad_placement_type || "",
                            is_supply_lda_content_compliant: input?.is_supply_lda_content_compliant || false,
                            is_supply_coppa_inventory_enable: input?.is_supply_coppa_inventory_enable || false,
                            ads_txt_content_url: input?.ads_txt_content_url || "",
                            app_ads_txt_content_url: input?.app_ads_txt_content_url || "",
                            seller_json_url: input?.seller_json_url || ""
                        },
                        demand_details: {
                            label: "Demand Information",
                            right_for_amagi_to_marketing: input?.right_for_amagi_to_marketing || false,
                            direct_sales_team_availability: input?.direct_sales_team_availability || false,
                            ad_quality_requirements: input?.ad_quality_requirements || "",
                            ad_domain_blocklist_file_url: !isEmpty(blocklistFileUrl) ? blocklistFileUrl : input.ad_domain_blocklist_file_url || "",
                            political_policy_allowed: input?.political_policy_allowed || false,
                            pharma_policy_allowed: input?.pharma_policy_allowed || false,
                            gambling_policy_allowed: input?.gambling_policy_allowed || false,
                            alcohol_policy_allowed: input?.alcohol_policy_allowed || false,
                            cannabis_policy_allowed: input?.cannabis_policy_allowed || false,
                            duplicate_creative_allowed: input?.duplicate_creative_allowed || false,
                            duplicate_advertiser_domain_allowed: input?.duplicate_advertiser_domain_allowed || false,
                            link_to_sale_collateral: input?.link_to_sale_collateral || "",
                            sales_collateral_file_url: !isEmpty(salesCollateralFileLink) ? salesCollateralFileLink : input.sales_collateral_file_url || "",
                            ad_category_restrictions:input?.ad_category_restrictions|| "",

                        },
                        technical_details: {
                            label: "Technical Details",
                            ad_server_url: input?.ad_server_url || "",
                            pre_fetching_ads: input?.pre_fetching_ads || "",
                            how_far_in_advance: input?.pre_fetch_advance_time || "",
                            ad_server_max_timeout: input?.ad_server_max_timeout || 0,
                            ad_server_max_timeout_scale: "ms",
                            positions_in_amagi_current_waterfall: input?.positions_in_amagi_current_waterfall || 1,
                            positions_in_amagi_current_waterfall_text: "Top Tier",
                            ad_stitching_enable: input?.ad_stitching_enable || "",
                            stitching_vendor_name: input?.stitching_vendor_name || "",
                            ssai_vendor: input?.ssai_vendor || "",
                            ad_poding_enable: input.ad_poding_enable ? "dynamic" : "static",
                            multiple_ad_response_enable: input?.multiple_ad_response_enable || "",
                            supported_vast_versions: input?.supported_vast_versions || "",
                            max_creative_length: input?.max_creative_length || 0,
                            max_creative_length_scale: "secs",
                            min_acceptable_bit_rate: input?.min_acceptable_bit_rate || 0,
                            min_acceptable_bit_rate_scale: "kbps",
                            max_acceptable_bit_rate: input?.max_acceptable_bit_rate || 0,
                            max_acceptable_bit_rate_scale: "kbps",
                            traffic:input?.traffic || ""
                        },
                        partner_expectations: {
                            label: "Partner Exceptions",
                            monthly_revenue: input?.monthly_revenue || "",
                            monthly_revenue_currency: "$",
                            net_ecpm: input?.net_ecpm || "",
                            fill_rate: input?.fill_rate || "",
                            any_other_kpis: input?.any_other_kpis || ""
                        },
                        app_supply_details: {
                            label: "App Supply Details",
                            dynamic_macros_list: input?.dynamic_macros_list || [],
                            supply_tags: input?.supply_tags?.length > 0 ? input.supply_tags.map(tag => {
                                return(
                                    {
                                        inventory_relationship: tag.inv_relationship || "exclusive",
                                        app_name_in_app_store: tag?.app_name_in_app_store || "",
                                        channel_name_stream: tag?.channel_name_stream || "",
                                        operating_system: tag.operating_system || "",
                                        app_bundle_id: tag.app_bundle_id || 0,
                                        supply_label_id: tag?.supply_label_id || 0,
                                        device_type: tag?.device_type || ""
                                    }
                                )
                            }) : []
                        }
                    }
                },
                type: input?.type || "",
                status: input?.status || "",
                requested_user: input?.user_email || "",
                request_job_id: request_job_id,
                comments: commentsData
            }
        }
    }else {
        return {
            createAdsPlusConfig: {
                config: {
                    customer_details: {
                        customer_name: input?.customer_name || "",
                        channel_name: input?.channel_name || "",
                        contacts: input?.contacts?.length > 0 ? input.contacts :  getContactDefaultValue()
                    },
                    platform_name: input?.platform_name || "",
                    amg_id: input?.platform_amg_id || "",
                    ts_stream_id: input?.ts_channel_id || "",
                    deal_type: input?.deal_type || "",
                    ss_core_owner_supply_tag_id: input?.ss_core_owner_supply_tag_id || 0,
                    ss_core_inventory_share: input?.ss_core_inventory_share || 0,
                    net_floor_expectations: input?.net_floor_expectations || "",
                    floor_rate: input?.floor_rate || "",
                    floor_rate_currency: "$",
                    so_document_file_url: !isEmpty(soFileUrl) ? soFileUrl : input.so_document_file_url || "",
                    additional_info_txt: input?.additional_info_txt || "",
                    ads_plus_supply_partner_id: input?.ads_plus_supply_partner_id || "",
                    connection_type: input?.connection_type || "",
                    commission: input?.commission || "",
                    ads_plus_prioritization: input?.ads_plus_prioritization || "",
                    onboarding_details: {
                        label: "Onboarding Details",
                        onb_config_details_file_url: configFileUrl,
                        onb_supply_details_file_url: supplyFileUrl,
                        supply_details: {
                            label: "Supply Description",
                            content_language: input?.content_language || "",
                            content_descriptions: input?.content_descriptions || "",
                            avg_pod_durations_in_sec: input?.avg_pod_durations_in_sec || 0,
                            device_type: input?.device_type?.length > 0 ? input.device_type : [],
                            inventory_available_in_geo: input?.inventory_available_in_geo?.length > 0 ? input.inventory_available_in_geo : [],
                            ad_placement_type: input?.ad_placement_type || "",
                            is_supply_lda_content_compliant: input?.is_supply_lda_content_compliant || false,
                            is_supply_coppa_inventory_enable: input?.is_supply_coppa_inventory_enable || false,
                            ads_txt_content_url: input?.ads_txt_content_url || "",
                            app_ads_txt_content_url: input?.app_ads_txt_content_url || "",
                            seller_json_url: input?.seller_json_url || ""
                        },
                        demand_details: {
                            label: "Demand Information",
                            right_for_amagi_to_marketing: input?.right_for_amagi_to_marketing || false,
                            direct_sales_team_availability: input?.direct_sales_team_availability || false,
                            ad_quality_requirements: input?.ad_quality_requirements || "",
                            ad_domain_blocklist_file_url: !isEmpty(blocklistFileUrl) ? blocklistFileUrl : input.ad_domain_blocklist_file_url || "",
                            political_policy_allowed: input?.political_policy_allowed || false,
                            pharma_policy_allowed: input?.pharma_policy_allowed || false,
                            gambling_policy_allowed: input?.gambling_policy_allowed || false,
                            alcohol_policy_allowed: input?.alcohol_policy_allowed || false,
                            cannabis_policy_allowed: input?.cannabis_policy_allowed || false,
                            duplicate_creative_allowed: input?.duplicate_creative_allowed || false,
                            duplicate_advertiser_domain_allowed: input?.duplicate_advertiser_domain_allowed || false,
                            link_to_sale_collateral: input?.link_to_sale_collateral || "",
                            sales_collateral_file_url: !isEmpty(salesCollateralFileLink) ? salesCollateralFileLink : input.sales_collateral_file_url || "",
                            ad_category_restrictions:input?.ad_category_restrictions|| ""
                        },
                        technical_details: {
                            label: "Technical Details",
                            ad_server_url: input?.ad_server_url || "",
                            pre_fetching_ads: input?.pre_fetching_ads || "",
                            how_far_in_advance: input?.pre_fetch_advance_time || "",
                            ad_server_max_timeout: input?.ad_server_max_timeout || 0,
                            ad_server_max_timeout_scale: "ms",
                            positions_in_amagi_current_waterfall: input?.positions_in_amagi_current_waterfall || 1,
                            positions_in_amagi_current_waterfall_text: "Top Tier",
                            ad_stitching_enable: input?.ad_stitching_enable || "",
                            stitching_vendor_name: input?.stitching_vendor_name || "",
                            ssai_vendor: input?.ssai_vendor || "",
                            ad_poding_enable: input.ad_poding_enable ? "dynamic" : "static",
                            multiple_ad_response_enable: input?.multiple_ad_response_enable || "",
                            supported_vast_versions: input?.supported_vast_versions || "",
                            max_creative_length: input?.max_creative_length || 0,
                            max_creative_length_scale: "secs",
                            min_acceptable_bit_rate: input?.min_acceptable_bit_rate || 0,
                            min_acceptable_bit_rate_scale: "kbps",
                            max_acceptable_bit_rate: input?.max_acceptable_bit_rate || 0,
                            max_acceptable_bit_rate_scale: "kbps",
                            traffic:input?.traffic || ""
                        },
                        partner_expectations: {
                            label: "Partner Exceptions",
                            monthly_revenue: input?.monthly_revenue || "",
                            monthly_revenue_currency: "$",
                            net_ecpm: input?.net_ecpm || "",
                            fill_rate: input?.fill_rate || "",
                            any_other_kpis: input?.any_other_kpis || ""
                        },
                        app_supply_details: {
                            label: "App Supply Details",
                            dynamic_macros_list: input?.dynamic_macros_list || [],
                            supply_tags: input?.supply_tags?.length > 0 ? input.supply_tags.map(tag => {
                                return(
                                    {
                                        inventory_relationship: tag.inv_relationship || "exclusive",
                                        app_name_in_app_store: tag?.app_name_in_app_store || "",
                                        channel_name_stream: tag?.channel_name_stream || "",
                                        operating_system: tag.operating_system || "",
                                        app_bundle_id: tag.app_bundle_id || 0,
                                        supply_label_id: tag?.supply_label_id || 0,
                                        device_type: tag?.device_type || ""
                                    }
                                )
                            }) : []
                        }
                    }
                },
                type: input?.type || "",
                status: input?.status || "",
                requested_user: input?.user_email || "",
                meta: {
                    ts_channel_id: input?.ts_channel_id || "",
                    ss_core_supply_router_id: input?.ss_core_supply_router_id || 0,
                    platform_amg_id: input?.platform_amg_id || "",
                    creation_type: input?.creation_type || "",
                    platform_name: input?.platform_name || "",
                    channel_name: input?.channel_name || ""
                }
            }
        };
    }
}

export const TSRequestGeneralDetailsFormConfig = (supplyPartnerOptions = [], amgId = "", supplyTagOptions = [], channelName = "", customerName = "") => {
    return [
        {
            label: "Channel name",
            value: "",
            type: "text_input",
            width: "442px",
            key: "channel_name",
            necessary: true,
            placeholder: "Channel name"
        },
        {
            label: "Customer name",
            value: "",
            type: "text_input",
            key: "customer_name",
            width: "442px",
            placeholder: "Customer name",
            necessary: true
        },
        {
            label: "Supply partner",
            value: "",
            type: "select",
            key: "ads_plus_supply_partner_id",
            width: "442px",
            options: supplyPartnerOptions,
            necessary: true
        },
        {
            label: "Contact",
            type:"inner_contact_table",
            key:"contacts",
        },
        {
            label: "AMG ID",
            value: amgId,
            type: "text_input",
            width: "442px",
            key: "amg_id",
            disabled: true
        },
        {
            label: "Pricing information",
            type: "heading",
        },
        {
            label: "Connection type",
            value: "",
            type: "select",
            placeholder: "Connection type",
            options: [
                { value: "vast", label: "Vast" },
                { value: "springserve_dc", label: "Springserve DC" }
            ],
            key: "connection_type",
            necessary: true
        },
        // {
        //     label: "Platform name",
        //     value: "",
        //     type: "text_input",
        //     width: "442px",
        //     key: "platform_name",
        // },
        //
        // {
        //     label: "Stream ID",
        //     value: "",
        //     type: "text_input",
        //     width: "442px",
        //     key: "ts_stream_id",
        // },
        {
            label: "Deal type",
            value: "",
            type: "select",
            placeholder: "Deal type",
            options: [
                { value: "inventory", label: "Inventory" },
                { value: "revenue", label: "Revenue - Exclusive" },
                { value: "fallback", label: "Revenue - Fallback" },
            ],
            key: "deal_type",
            necessary: true
        },
        {
            label: "Commission %",
            value: "",
            type: "input",
            width: "41px",
            post_unit:"%",
            necessary: true,
            key: "commission",
            placeholder: " "
        },
        {
            label: "Ads+ Inv. share %",
            value: "",
            type: "input",
            width: "41px",
            placeholder: " ",
            post_unit:"%",
            necessary: true,
            key: "ss_core_inventory_share"
        },
        {
            label: "Ads+ Prioritization",
            value: "",
            type: "select",
            placeholder: "Ads+ Prioritization",
            options: [
                { value: "fixed_cpm", label: "Fixed CPM" },
                { value: "hard_floor", label: "RevShare - Hard Floor" },
                { value: "soft_floor", label: "RevShare - Soft Floor" }
            ],
            key: "ads_plus_prioritization",
        },
        {
            label: "Supply tag",
            value: "",
            type: "select",
            placeholder: "Inventory owner",
            options: supplyTagOptions,
            key: "ss_core_owner_supply_tag_id",
            necessary: true
        },
        // {
        //     label: "Ads+ Inv. share %",
        //     value: "",
        //     type: "input",
        //     width: "41px",
        //     placeholder: "%",
        //     post_unit:"%",
        //     necessary: true,
        //     key: "ss_core_inventory_share"
        // },
        {
            label: "Net floor expectation",
            value: "",
            type: "select",
            key:"net_floor_expectations",
            options: [
                { value: "soft", label: "Soft" },
                { value: "hard", label: "Hard" },
                {value: "fixed", label:"Fixed"}
            ],
        },
        {
            label: "Floor rate",
            value: "",
            type: "input",
            preUnit : "$",
            key:"floor_rate",
            width:"100px",
            placeholder: "Rate"
        },
        {
            label: "SO documents",
            value: "",
            type: "file_input",
            config: {
                fileType: "Only pdf",
                supportedType: ".pdf",
                subMessage: "Upload your SO document.",
                headMessage: "",
            },
            key: "so_document_file_url",
            necessary: true
        },
        {
            label: "Additional info (Optional)",
            value: "",
            type: "text_input",
            width:"442px",
            map_key: "config",
            placeholder: "Additional info",
            key:"additional_info_txt",
            textLimit: 200
        },
    ]
};


import axios from "axios";
import { REPORT_BFF_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

const getDemandGoalRevenue = async (month = 0, year = 2024) => {
    try {
        const query = `query MyQuery {
          demand_goal_tracking(where: {month: ${month}, year: ${year}, goal_name: "revenue"}) {
            achieved
            projected
            target
          }
        }`;
        const headers = adsHeader()

        const response = await axios.post(REPORT_BFF_URI, { query }, { headers: headers });
        if(response?.data?.data?.demand_goal_tracking && response.status === 200){
            return response.data.data.demand_goal_tracking;
        }

        return {}
    } catch (error) {
        console.error("Error fetching while getSupplyHealthTracker:", error);
        return null;
    }
};

export default getDemandGoalRevenue;

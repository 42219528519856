export const requestCustomerDetailsConfig = [
    {
        label: "Contacts",
        type: "table_contacts",
        key: "contacts",
        map_key: "customer_details",
    },
    {
        label: "Supply partner",
        type: "text",
        key: "ads_plus_supply_partner_id",
        map_key: "config",
    },
    {
        label: "Pricing information",
        type: "sub_heading",
    },
    {
        label: "Supply tag",
        type: "text",
        key: "ss_core_owner_supply_tag_id",
        map_key: "config",
    },
    {
        label: "Deal type",
        type: "text",
        key: "deal_type",
        map_key: "config",
    },
    {
        label: "Commission %",
        type: "text",
        key: "commission",
        map_key: "config",
        suffix: "%",
    },
    {
        label: "Ads+ inventory share %",
        type: "text",
        key: "ss_core_inventory_share",
        map_key: "config",
        suffix: "%"
    },
    {
        label: "Net floor expectation",
        type: "text",
        key: "net_floor_expectations",
        map_key: "config",
    },
    {
        label: "Floor rate",
        type: "text",
        key: "floor_rate",
        map_key: "config",
        prefix: "$",
    },
    {
        label: "SO document",
        type: "download",
        key: "so_document_file_url",
        map_key: "config",
    },
    // {
    //     label: "Onboarding Document",
    //     type: "download_array",
    //     key: "onb_config_details_file_url",
    //     map_key: "onboarding_details",
    // },
    {
        label: "Additional Info",
        type: "text",
        key: "additional_info_txt",
        map_key: "config",
    }
];

export const requestCustomerDetailsConfigNonTS = [
    {
        label: "Contacts",
        type: "table_contacts",
        key: "contacts",
        map_key: "customer_details",
    },
    {
        label: "Supply partner",
        type: "text",
        key: "ads_plus_supply_partner_id",
        map_key: "config",
    },
    {
        label: "Pricing information",
        type: "sub_heading",
    },
    {
        label: "Connection type",
        type: "text",
        key: "connection_type",
        map_key: "config",
    },
    {
        label: "Deal type",
        type: "text",
        key: "deal_type",
        map_key: "config",
    },
    {
        label: "Ads+ inventory share %",
        type: "text",
        key: "ss_core_inventory_share",
        map_key: "config",
        suffix: "%",
    },
    {
        label: "Commission %",
        type: "text",
        key: "commission",
        map_key: "config",
        suffix: "%",
    },
    {
        label: "Ads+ prioritization",
        type: "text",
        key: "ads_plus_prioritization",
        map_key: "config",
    },
    {
        label: "Net floor expectation",
        type: "text",
        key: "net_floor_expectations",
        map_key: "config",
    },
    {
        label: "Floor rate",
        type: "text",
        key: "floor_rate",
        map_key: "config",
        prefix: "$",
    },
    {
        label: "SO document",
        type: "download",
        key: "so_document_file_url",
        map_key: "config",
    },
    // {
    //     label: "Onboarding Document",
    //     type: "download_array",
    //     key: "onb_config_details_file_url",
    //     map_key: "onboarding_details",
    // },
    {
        label: "Additional Info",
        type: "text",
        key: "additional_info_txt",
        map_key: "config",
    }
];
